import { Delete } from "@mui/icons-material"
import AddIcon from "@mui/icons-material/Add"
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward"
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import React, { Dispatch, useContext, useEffect, useState } from "react"
import { AffectMaterialInput } from "../../../../components/inputs/affect-material-input/affect-material-input"
import { NumberInput } from "../../../../components/inputs/number-input/NumberInput"
import { ErrorContext } from "../../../../components/layout/error-snackbar"
import { CalculationContext, CalculationStore } from "../../../../core/context/calculation/calculation-context"
import { CodeCustomContext, CodeCustomStore } from "../../../../core/context/code-acv/code-custom-context"
import { CodesCompletionsContext, CodesCompletionsStore } from "../../../../core/context/code-acv/codes-completions-context"
import { ProjectContext, ProjectStore } from "../../../../core/context/project/project-context"
import { CodesCompletionsPerCodeParent } from "../../../../core/data-structures/codes-completions-per-code-parent"
import CodeCustom from "../../../../core/dto/code-acv-custom/code-custom"
import CodeCustomCreation from "../../../../core/dto/code-acv-custom/code-custom-creation"
import CodeReference from "../../../../core/dto/code-acv/code-reference"
import { CodeCompletion } from "../../../../core/dto/code-completion/code-completion"
import { CodeCompletionUpdate } from "../../../../core/dto/code-completion/code-completion-update"
import { CodeExtrait } from "../../../../core/dto/code-extrait/code-extrait"
import { IniesRecord } from "../../../../core/dto/material/IniesRecord"
import { Material } from "../../../../core/dto/material/material"
import { MaterialRecord } from "../../../../core/dto/material/MaterialRecord"
import { useCode } from "../../../../core/hooks/use-code"
import { useMaterial } from "../../../../core/hooks/use-material"
import { getLabelForIniesUnit } from "../../../../core/services/unit-service"
import AdditionalAndCompletionModal from "./additional-and-completion-modal"
import CodeCompletionSelectionModal from "./code-completion-selection-modal"
import { CompletionListV1v2 } from "./completion-list-v1v2"
import AssignMaterialCalculationModalFormModal from "./material-page-modal-add-inies/assign-material-calculation-modal"
import { MaterialPageCard } from "./material-page-modal-add-inies/material-page-card"

type Action = (codeCompletion?: CodeCompletion) => CodeCompletion | undefined

type IProps = {
  selectedCodeParentV1: CodeCompletion | CodeExtrait | undefined
  selectedCodeParentV2: CodeCompletion | CodeExtrait | undefined // This is only useful when isSameV1V2 and it's currently v1
  variantCategory: 1 | 2
  createNewCodeCompletionFromCustom(
    codeCustomToAdd: CodeCustom,
    codeParent: CodeExtrait | CodeCompletion,
    variant: number
  ): CodeCompletion | undefined
  createNewCodeCompletionFromAcv(
    codeAcvToAdd: CodeReference,
    codeParent: CodeExtrait | CodeCompletion,
    variant: number
  ): CodeCompletion | undefined
  sendCompletionList(
    codeParentIdV1: string | undefined,
    codeCompletionListV1: CodeCompletion[] | undefined,
    codeParentIdV2: string | undefined,
    codeCompletionListV2: CodeCompletion[] | undefined
  ): Promise<CompletionListV1v2>
  disableSameValuesIfNeed(): void
  sameValuesV1V2: boolean
  disabled: boolean
}

export default function FormAddCompletion({
  selectedCodeParentV1,
  selectedCodeParentV2,
  variantCategory,
  createNewCodeCompletionFromCustom,
  createNewCodeCompletionFromAcv,
  sendCompletionList,
  disableSameValuesIfNeed,
  sameValuesV1V2,
  disabled,
}: IProps): React.JSX.Element {
  const {
    codesCompletionsPerCodeParentV1,
    codesCompletionsPerCodeParentV2,
    setCodesCompletionsPerCodeParentV1,
    setCodesCompletionsPerCodeParentV2,
  } = useContext<CodesCompletionsStore>(CodesCompletionsContext)
  const openErrorSnackbar = useContext(ErrorContext)
  const { project } = useContext<ProjectStore>(ProjectContext)
  const { calculation } = useContext<CalculationStore>(CalculationContext)
  const { codeCustomPerParentCode, setCodeCustomPerParentCode } = useContext<CodeCustomStore>(CodeCustomContext)

  const { addIniesToProject } = useMaterial()
  const { deleteCodeCompletion, createCodeAcvCustom, updateCodeCompletion } = useCode()

  const [displayMaterialModalOpen, setDisplayMaterialModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [codeCompletionDeleting, setCodeCompletionDeleting] = useState<Record<string, boolean>>({})

  const [selectedIdFilter, setSelectedIdFilter] = useState("")
  const [codeCompletionSelectedIndex, setCodeCompletionSelectedIndex] = useState<number>(-1)
  const [isAssignMaterialModalOpen, setIsAssignMaterialModalOpen] = useState<boolean>(false)
  const [isCompletionModalOpen, setIsCompletionModalOpen] = useState<boolean>(false)
  const [isCodeCustomModalOpen, setIsCodeCustomModalOpen] = useState<boolean>(false)
  const [newCodeCustom, setNewCodeCustom] = useState<CodeCustomCreation>(new CodeCustomCreation())

  useEffect(() => {
    const newList: CodeCompletion[] | undefined = getCurrentList()
    resetIndex(newList)
  }, [variantCategory, selectedCodeParentV1, selectedCodeParentV2, getCurrentList])

  function resetIndex(list: CodeCompletion[] | undefined): void {
    if (list && list.length > 0 && list.length - 1 < codeCompletionSelectedIndex) {
      setCodeCompletionSelectedIndex(0)
    } else if (!list || list.length === 0) {
      setCodeCompletionSelectedIndex(-1)
    } else {
      // Do nothing, keep the current index
    }
  }

  function getCompletionListForCodeAndVariant(variant: 1 | 2): CodeCompletion[] | undefined {
    return variant === 1
      ? codesCompletionsPerCodeParentV1.getForCodeParent(selectedCodeParentV1)
      : codesCompletionsPerCodeParentV2.getForCodeParent(selectedCodeParentV2)
  }

  function getCurrentList(): CodeCompletion[] | undefined {
    return getCompletionListForCodeAndVariant(variantCategory)
  }

  function updateReuseAction(checked: boolean): Action {
    return (codeCompletion: CodeCompletion | undefined) => {
      if (codeCompletion) {
        codeCompletion.materialId = undefined
        codeCompletion.material = undefined
        codeCompletion.reuse = checked
        return codeCompletion
      } else {
        return undefined
      }
    }
  }

  function updateCompletionAction(): Action {
    // This function does nothing, because the codes where updated when the field changed value, not when submitting
    return (codeCompletion: CodeCompletion | undefined) => codeCompletion
  }

  function updateCodeCompletionListState(
    newListV1: CodeCompletion[] | undefined,
    newListV2: CodeCompletion[] | undefined
  ): void {
    // Shadow update of codesCompletionsPerCodeParent : do not trigger redraw with codesCompletionsPerCodeParentV1 or codesCompletionsPerCodeParentV2
    // Because setCodeCompletionList already redraw what's visible
    // while one of them would redraw the whole page
    if (variantCategory === 1 && sameValuesV1V2) {
      updateStateCompletionForParent(
        codesCompletionsPerCodeParentV1,
        setCodesCompletionsPerCodeParentV1,
        selectedCodeParentV1,
        newListV1
      )
      if (newListV2 !== undefined && selectedCodeParentV2) {
        codesCompletionsPerCodeParentV2.setList(selectedCodeParentV2, newListV2)
      }
    } else if (variantCategory === 2 && sameValuesV1V2) {
      updateStateCompletionForParent(
        codesCompletionsPerCodeParentV2,
        setCodesCompletionsPerCodeParentV2,
        selectedCodeParentV2,
        newListV2
      )
      disableSameValuesIfNeed()
    } else if (variantCategory === 1 && !sameValuesV1V2) {
      updateStateCompletionForParent(
        codesCompletionsPerCodeParentV1,
        setCodesCompletionsPerCodeParentV1,
        selectedCodeParentV1,
        newListV1
      )
    } else if (variantCategory === 2 && !sameValuesV1V2) {
      updateStateCompletionForParent(
        codesCompletionsPerCodeParentV2,
        setCodesCompletionsPerCodeParentV2,
        selectedCodeParentV2,
        newListV2
      )
    }
  }

  function updateStateCompletionForParent(
    codesCompletionsPerCodeParent: CodesCompletionsPerCodeParent,
    setCodesCompletionsPerCodeParent: Dispatch<any>,
    selectedCodeParent: CodeCompletion | CodeExtrait | undefined,
    newCompletionList: CodeCompletion[] | undefined
  ): void {
    if (newCompletionList !== undefined) {
      const newCompletion = codesCompletionsPerCodeParent.shallowClone()
      newCompletion.setList(selectedCodeParent, newCompletionList)
      setCodesCompletionsPerCodeParent(newCompletion)
    }
  }

  function handleReuseChange(_: any, checked: boolean): void {
    if (project.id && codeCompletionSelectedIndex !== -1) {
      submitOneCompletionIfV1V2New(project.id, updateReuseAction(checked))
    }
  }

  function applyAction(list: CodeCompletion[] | undefined, action: Action, projectId: string): CodeCompletion | undefined {
    if (list && list.length > codeCompletionSelectedIndex && list[codeCompletionSelectedIndex]) {
      const codeCompletion = list[codeCompletionSelectedIndex]
      if (codeCompletion) {
        action(codeCompletion)
        const codeCompletionUpdate = CodeCompletionUpdate.from(codeCompletion)
        updateCodeCompletion(projectId, codeCompletionUpdate)
        return codeCompletion
      }
    }

    return undefined
  }

  function submitOneCompletionIfV1V2New(projectId: string, action: Action): void {
    if (codeCompletionSelectedIndex !== -1) {
      const listV1 = codesCompletionsPerCodeParentV1.getForCodeParent(selectedCodeParentV1)
      const listV2 = codesCompletionsPerCodeParentV2.getForCodeParent(selectedCodeParentV2)

      if (variantCategory === 1 && sameValuesV1V2) {
        const updatedCodeV1 = applyAction(listV1, action, projectId)
        const updatedCodeV2 = applyAction(listV2, action, projectId)

        codesCompletionsPerCodeParentV1.getForCodeParent(selectedCodeParentV1)

        if (updatedCodeV1) {
          const newV1Map = CodesCompletionsPerCodeParent.setCodeCompletionList(
            codesCompletionsPerCodeParentV1,
            selectedCodeParentV1,
            listV1
          )
          setCodesCompletionsPerCodeParentV1(newV1Map)
        }
        // Shadow update the v2 list
        if (listV2 && updatedCodeV2) {
          listV2[codeCompletionSelectedIndex] = updatedCodeV2
        }
      } else if (variantCategory === 2 && sameValuesV1V2) {
        const updatedCodeV2 = applyAction(listV2, action, projectId)
        disableSameValuesIfNeed()
        if (updatedCodeV2) {
          const newV2Map = CodesCompletionsPerCodeParent.setCodeCompletionList(
            codesCompletionsPerCodeParentV2,
            selectedCodeParentV2,
            listV2
          )
          setCodesCompletionsPerCodeParentV2(newV2Map)
        }
      } else if (variantCategory === 1 && !sameValuesV1V2) {
        const updatedCodeV1 = applyAction(listV1, action, projectId)
        if (updatedCodeV1) {
          const newV1Map = CodesCompletionsPerCodeParent.setCodeCompletionList(
            codesCompletionsPerCodeParentV1,
            selectedCodeParentV1,
            listV1
          )
          setCodesCompletionsPerCodeParentV1(newV1Map)
        }
      } else if (variantCategory === 2 && !sameValuesV1V2) {
        const updatedCodeV2 = applyAction(listV2, action, projectId)

        if (updatedCodeV2) {
          const newV2Map = CodesCompletionsPerCodeParent.setCodeCompletionList(
            codesCompletionsPerCodeParentV2,
            selectedCodeParentV2,
            listV2
          )
          setCodesCompletionsPerCodeParentV2(newV2Map)
        }
      }
    }
  }

  function submitUpdateCompletion(): void {
    if (project?.id && !disabled) {
      submitOneCompletionIfV1V2New(project.id, updateCompletionAction())
    }
  }

  function handleChangeNumber(name: string, value: string): void {
    handleChange({ target: { name, value } })
  }

  function handleChange(e: { target: { name: string; value: string } }): void {
    const newHypothesis = e.target.value
    const field = e.target.name
    if (sameValuesV1V2) {
      const listV1 = codesCompletionsPerCodeParentV1.getForCodeParent(selectedCodeParentV1)
      const listV2 = codesCompletionsPerCodeParentV2.getForCodeParent(selectedCodeParentV2)
      const newListV1 = updateField(listV1, newHypothesis, field)
      const newListV2 = updateField(listV2, newHypothesis, field)
      updateCodeCompletionListState(newListV1, newListV2)
    } else {
      const currentList =
        variantCategory === 1
          ? codesCompletionsPerCodeParentV1.getForCodeParent(selectedCodeParentV1)
          : codesCompletionsPerCodeParentV2.getForCodeParent(selectedCodeParentV2)

      if (currentList) {
        const newList = updateField(currentList, newHypothesis, field)
        if (variantCategory === 1) {
          updateCodeCompletionListState(newList, codesCompletionsPerCodeParentV2.getForCodeParent(selectedCodeParentV1))
        } else if (variantCategory === 2) {
          updateCodeCompletionListState(codesCompletionsPerCodeParentV2.getForCodeParent(selectedCodeParentV2), newList)
        }
      }
    }
  }

  function updateField(list: CodeCompletion[] | undefined, value: string, name: string): CodeCompletion[] | undefined {
    let newList
    if (list) {
      newList = [...list]
      const updatedItem = {
        ...list[codeCompletionSelectedIndex],
        [name]: name === "amount" ? parseFloat(value) : value,
      }
      newList[codeCompletionSelectedIndex] = { ...updatedItem }
    }
    return newList
  }

  function submitNewList(newAcvList: CodeReference[], newCustomList: CodeCustom[]): void {
    if (sameValuesV1V2 && selectedCodeParentV1 && selectedCodeParentV2 && variantCategory === 2) {
      const newListV2 = createNewList(newAcvList, newCustomList, 2, selectedCodeParentV2)
      submitNewLists(getCompletionListForCodeAndVariant(1), newListV2)
    } else if (sameValuesV1V2 && selectedCodeParentV1 && selectedCodeParentV2 && variantCategory === 1) {
      const newListV1 = createNewList(newAcvList, newCustomList, 1, selectedCodeParentV1)
      const newListV2 = createNewList(newAcvList, newCustomList, 2, selectedCodeParentV2)
      submitNewLists(newListV1, newListV2)
    } else if (variantCategory === 1 && selectedCodeParentV1) {
      const newListV1 = createNewList(newAcvList, newCustomList, 1, selectedCodeParentV1)
      submitNewLists(newListV1, getCompletionListForCodeAndVariant(2))
    } else if (variantCategory === 2 && selectedCodeParentV2) {
      // The check to desactivate isSame checkbox is handled later, in submitNewLists
      const newListV2 = createNewList(newAcvList, newCustomList, 2, selectedCodeParentV2)
      submitNewLists(getCompletionListForCodeAndVariant(1), newListV2)
    } else if (variantCategory === 2 && sameValuesV1V2) {
      openErrorSnackbar(new Error("Les variantes sont désynchronisée"))
    }
  }

  function createNewList(
    newAcvList: CodeReference[],
    newCustomList: CodeCustom[],
    variantNumer: 1 | 2,
    parent: CodeExtrait | CodeCompletion
  ): CodeCompletion[] {
    const oldListV1: CodeCompletion[] = getCompletionListForCodeAndVariant(variantNumer) || []
    const newCodeCompletionList: CodeCompletion[] = []

    newAcvList.forEach((codeAcv) => {
      const index = oldListV1.findIndex(
        (oldCode) =>
          oldCode.code === codeAcv.code &&
          oldCode.name === codeAcv.name &&
          oldCode.descriptionFromCodeAcv === codeAcv.description &&
          oldCode.lot === codeAcv.lot &&
          oldCode.sousLot === codeAcv.sousLot
      )
      if (index === -1) {
        const newCode = createNewCodeCompletionFromAcv(codeAcv, parent, variantNumer)
        if (newCode) {
          newCodeCompletionList.push(newCode)
        }
      } else {
        const codeCompletion = oldListV1.splice(index, 1)[0]
        newCodeCompletionList.push(codeCompletion)
      }
    })

    newCustomList.forEach((codeCustom) => {
      const index = oldListV1.findIndex(
        (oldCode) =>
          oldCode.code === codeCustom.code &&
          oldCode.name === codeCustom.name &&
          oldCode.descriptionFromCodeAcv === codeCustom.description &&
          oldCode.lot === codeCustom.lot &&
          oldCode.sousLot === codeCustom.sousLot
      )
      if (index === -1) {
        const newCode = createNewCodeCompletionFromCustom(codeCustom, parent, variantNumer)
        if (newCode) {
          newCodeCompletionList.push(newCode)
        }
      } else {
        const codeCompletions = oldListV1.splice(index, 1)[0]
        newCodeCompletionList.push(codeCompletions)
      }
    })

    return newCodeCompletionList
  }

  function submitNewLists(newListV1: CodeCompletion[] | undefined, newListV2: CodeCompletion[] | undefined): void {
    setIsLoading(true)
    // Here it needs to wait for server response because we need the ids of code to be able to update them later
    // This action is very different between "codes extraits" and "additional codes"

    sendCompletionList(selectedCodeParentV1?.id, newListV1, selectedCodeParentV2?.id, newListV2)
      .then((listCreated: { listV1: CodeCompletion[]; listV2: CodeCompletion[] }) => {
        updateCodeCompletionListState(listCreated.listV1, listCreated.listV2)
        const list = variantCategory === 1 ? listCreated.listV1 : listCreated.listV2
        resetIndex(list)
      })
      .finally(() => {
        setIsLoading(false)
      })

    setIsCompletionModalOpen(false)
  }

  function handleDeleteCodeCompletion(codeCompletionToRemove: CodeCompletion, index: number): void {
    if (project?.id && codeCompletionToRemove?.variantId && codeCompletionToRemove?.id) {
      setCodeCompletionDeleting({ ...codeCompletionDeleting, [codeCompletionToRemove.code]: true })
      if (variantCategory === 1 && sameValuesV1V2) {
        const promises = []
        const promise1: Promise<CodeCompletion[] | undefined> = deleteCodeCompletion(
          project.id,
          codeCompletionToRemove.variantId,
          codeCompletionToRemove.id
        ).then(() => {
          const oldListV1 = getCompletionListForCodeAndVariant(1)
          const newListV1: CodeCompletion[] | undefined = oldListV1
            ? oldListV1.filter((item) => item.id !== codeCompletionToRemove.id)
            : undefined
          resetIndex(newListV1)
          return newListV1
        })
        promises.push(promise1)

        const codeCompletionListV2 = codesCompletionsPerCodeParentV2.getForCodeParent(selectedCodeParentV2)
        if (codeCompletionListV2) {
          const codeCompletionToRemoveV2 = codeCompletionListV2[index]
          if (codeCompletionToRemoveV2?.id) {
            const promise2: Promise<CodeCompletion[] | undefined> = deleteCodeCompletion(
              project.id,
              calculation.variantId2,
              codeCompletionToRemoveV2.id
            ).then(() => codeCompletionListV2.filter((item) => item.id !== codeCompletionToRemoveV2.id))
            promises.push(promise2)
          }
        }
        Promise.all(promises)
          .then((newLists) => {
            const newListV1 = newLists && newLists[0] ? newLists[0] : undefined
            const newListV2 = newLists && newLists[1] ? newLists[1] : undefined
            updateCodeCompletionListState(newListV1, newListV2)
            setCodeCompletionDeleting({ ...codeCompletionDeleting, [codeCompletionToRemove.code]: false })
          })
          .catch((e) => {
            setCodeCompletionDeleting({ ...codeCompletionDeleting, [codeCompletionToRemove.code]: false })
            throw e
          })
      } else if (variantCategory === 2) {
        deleteCodeCompletion(project.id, calculation.variantId2, codeCompletionToRemove.id)
          .then(() => {
            setCodeCompletionDeleting({ ...codeCompletionDeleting, [codeCompletionToRemove.code]: false })
            const codeCompletionListV2 = codesCompletionsPerCodeParentV2.getForCodeParent(selectedCodeParentV2)
            const newListV2 = codeCompletionListV2
              ? codeCompletionListV2.filter((item) => item.id !== codeCompletionToRemove.id)
              : undefined
            const newListV1 = getCompletionListForCodeAndVariant(1)
            resetIndex(newListV2)
            updateCodeCompletionListState(newListV1, newListV2)
          })
          .catch((e) => {
            setCodeCompletionDeleting({ ...codeCompletionDeleting, [codeCompletionToRemove.code]: false })
            throw e
          })
      } else {
        deleteCodeCompletion(project.id, calculation.variantId1, codeCompletionToRemove.id)
          .then(() => {
            setCodeCompletionDeleting({ ...codeCompletionDeleting, [codeCompletionToRemove.code]: false })
            const codeCompletionListV1 = codesCompletionsPerCodeParentV1.getForCodeParent(selectedCodeParentV1)
            const newListV1 = codeCompletionListV1
              ? codeCompletionListV1.filter((item) => item.id !== codeCompletionToRemove.id)
              : undefined
            const newListV2 = getCompletionListForCodeAndVariant(2)
            resetIndex(newListV1)
            updateCodeCompletionListState(newListV1, newListV2)
          })
          .catch((e) => {
            setCodeCompletionDeleting({ ...codeCompletionDeleting, [codeCompletionToRemove.code]: false })
            throw e
          })
      }
    }
  }

  function updateMaterialAction(material: Material): Action {
    return (codeCompletion: CodeCompletion | undefined) => {
      if (codeCompletion) {
        codeCompletion.material = material
        codeCompletion.materialId = material.id
        codeCompletion.reuse = false
        return codeCompletion
      } else {
        return undefined
      }
    }
  }

  async function handleAssignMaterialToCompletion(
    newValue: IniesRecord | MaterialRecord,
    isMaterial?: boolean
  ): Promise<void> {
    if (project?.id && codeCompletionSelectedIndex !== -1) {
      let material: Material
      if (!isMaterial) {
        // If it is an inies record, we need to create the material into the "project material lib"
        material = await addIniesToProject(project.id, `${newValue.iniesId}`)
      } else {
        // If it is a MaterialRecord, it means it comes from the "project material lib"
        material = Material.from(newValue, project)
      }

      if (project.id) {
        submitOneCompletionIfV1V2New(project.id, updateMaterialAction(material))
      }
    }
  }

  function openAssignMaterialModal(): void {
    const currentList = getCurrentList()
    if (codeCompletionSelectedIndex !== -1 && currentList && currentList[codeCompletionSelectedIndex]) {
      const codeCompletionId = currentList[codeCompletionSelectedIndex]?.code
      if (codeCompletionId) {
        setSelectedIdFilter(`${selectedCodeParentV1?.id}${codeCompletionId}`)
      }
    }
    setIsAssignMaterialModalOpen(true)
  }

  function verificationSameCustomCode(): boolean {
    const parentCode: string | undefined = variantCategory === 1 ? selectedCodeParentV1?.code : selectedCodeParentV2?.code
    if (parentCode === undefined) {
      return false
    }
    return codeCustomPerParentCode[parentCode]?.map((codeCustom) => codeCustom.name).includes(newCodeCustom.name)
  }

  function createCodeCustom(): void {
    setIsCodeCustomModalOpen(false)
    if (verificationSameCustomCode()) {
      openErrorSnackbar(new Error("Il ne peut pas y avoir 2 codes avec le même nom."))
      return
    }
    if (project?.id) {
      const parentCode: string | undefined = variantCategory === 1 ? selectedCodeParentV1?.code : selectedCodeParentV2?.code
      if (parentCode === undefined) {
        return
      }
      newCodeCustom.parentCode = parentCode
      newCodeCustom.projectId = project.id
      createCodeAcvCustom(project.id, newCodeCustom).then((codeCustom) => {
        const list: CodeCustom[] = codeCustomPerParentCode[parentCode]
        let newList
        if (list) {
          newList = [...list, codeCustom]
        } else {
          newList = [codeCustom]
        }
        setCodeCustomPerParentCode({ ...codeCustomPerParentCode, [parentCode]: newList })
        setNewCodeCustom(new CodeCustomCreation())
      })
    }
  }

  function handleCodeCustomChange(field: string, value: string | undefined): void {
    setNewCodeCustom({ ...newCodeCustom, [field]: value })
  }

  function getCurrentListNotNull(): CodeCompletion[] {
    const currentList = getCurrentList()
    return currentList ?? []
  }

  function getCurrentMaterial(): Material | undefined {
    const currentList = getCompletionListForCodeAndVariant(variantCategory)

    if (currentList) {
      const currentListElement = currentList[codeCompletionSelectedIndex]
      return currentListElement?.material
    } else {
      return undefined
    }
  }

  function isReuseChecked(): boolean {
    const currentList = getCurrentList()
    if (currentList && codeCompletionSelectedIndex !== -1 && currentList.length > codeCompletionSelectedIndex) {
      return !!currentList[codeCompletionSelectedIndex]?.reuse
    }
    return false
  }

  function getAmount(): number {
    const currentList = getCurrentList()
    if (currentList && codeCompletionSelectedIndex !== -1 && currentList.length > codeCompletionSelectedIndex) {
      return currentList[codeCompletionSelectedIndex]?.amount || 0
    }
    return 0
  }

  function getCurrentHypothesis(): string {
    const currentList = getCurrentList()
    if (currentList && codeCompletionSelectedIndex !== -1 && currentList.length > codeCompletionSelectedIndex) {
      return currentList[codeCompletionSelectedIndex]?.hypothesis
    }
    return ""
  }

  function getCodeCustomIdsList(): string[] {
    return getCurrentListNotNull().map((codeCustom) => codeCustom.name)
  }

  function closeAssignMaterialModal(): void {
    setIsAssignMaterialModalOpen(false)
  }

  function closeCodeCompletionSelectionModal(): void {
    setIsCompletionModalOpen(false)
  }

  function closeEditCodeCustomModal(): void {
    setIsCodeCustomModalOpen(false)
  }

  function openCodeCustomModal(): void {
    setIsCodeCustomModalOpen(true)
  }

  function deleteButton(codeCompletion: CodeCompletion, index: number): React.JSX.Element {
    const code: string = codeCompletion.code
    const isDeleting: any = codeCompletionDeleting[code]
    if (isDeleting) {
      return <CircularProgress sx={{ ml: "10px", mr: "10px" }} />
    } else {
      return (
        <IconButton disabled={disabled} onClick={() => handleDeleteCodeCompletion(codeCompletion, index)}>
          <Delete />
        </IconButton>
      )
    }
  }

  function removeMaterialFromCode(codeCompletion: CodeCompletion | undefined): CodeCompletion | undefined {
    if (codeCompletion) {
      codeCompletion.materialId = undefined
      codeCompletion.material = undefined
      codeCompletion.reuse = false
      return codeCompletion
    } else {
      return undefined
    }
  }

  function removeMaterial(): void {
    if (project?.id) {
      submitOneCompletionIfV1V2New(project.id, removeMaterialFromCode)
    }
  }

  function getSelectedCodeCompletion(): CodeCompletion | undefined {
    const currentList = getCurrentList()
    if (currentList && codeCompletionSelectedIndex !== -1 && currentList.length > codeCompletionSelectedIndex) {
      return currentList[codeCompletionSelectedIndex]
    } else {
      return undefined
    }
  }

  return (
    <>
      {/*Modal to assign a material on the completion code*/}
      <AssignMaterialCalculationModalFormModal
        handleAssignMaterial={handleAssignMaterialToCompletion}
        isAssignMaterialModalOpen={isAssignMaterialModalOpen}
        closeAssignMaterialModal={closeAssignMaterialModal}
        materialId={selectedIdFilter}
      />
      {/*Modal select the code completion on this code extrait*/}
      <CodeCompletionSelectionModal
        isModalOpen={isCompletionModalOpen}
        closeCodeCompletionSelectionModal={closeCodeCompletionSelectionModal}
        selectedCodeParent={variantCategory === 1 ? selectedCodeParentV1 : selectedCodeParentV2}
        codesCompletionsList={getCurrentListNotNull()}
        codeCompletionStringList={getCodeCustomIdsList()}
        setOpenCodeCustomCreationModal={openCodeCustomModal}
        submitNewList={submitNewList}
      />
      {/*Modal to create and update code custom, this is open from the addCodeCompletion modal*/}
      <AdditionalAndCompletionModal
        isOpen={isCodeCustomModalOpen}
        handleCloseModal={closeEditCodeCustomModal}
        handleChange={handleCodeCustomChange}
        formValues={newCodeCustom}
        handleCreateOrUpdate={createCodeCustom}
      />

      <Modal
        open={displayMaterialModalOpen}
        onClose={() => setDisplayMaterialModalOpen(false)}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        aria-labelledby="add-material-modal"
        aria-describedby="modal-modal-description">
        <Box>
          <MaterialPageCard
            material={getCurrentMaterial()}
            setDisplayMaterialModalOpen={setDisplayMaterialModalOpen}
            isMaterial
          />
        </Box>
      </Modal>
      <Typography sx={{ textTransform: "uppercase", fontWeight: "bold", mb: 4 }}>Compléter la donnée</Typography>
      <Grid container columnSpacing={6}>
        <Grid item xs={5} display="flex" flexDirection="column" justifyContent="space-between">
          <Box component="div">
            <Button
              disabled={disabled}
              style={{ textTransform: "none" }}
              sx={{ mb: 2 }}
              onClick={() => setIsCompletionModalOpen(true)}>
              <AddIcon sx={{ mr: 2 }} />
              Ajouter une donnée de complétion
            </Button>

            {isLoading ? (
              <Box display="flex" justifyContent="center">
                <CircularProgress color="inherit" size={20} />
              </Box>
            ) : (
              <Stack>
                {getCurrentListNotNull().map((codeCompletion, index) => (
                  <Stack width="100%" key={`${codeCompletion.code}-`}>
                    <Grid container sx={{ width: "95%" }}>
                      <Grid item xs={!disabled ? 10 : 12}>
                        <Button
                          style={{ textTransform: "none" }}
                          onClick={() => {
                            setCodeCompletionSelectedIndex(index)
                          }}
                          fullWidth
                          sx={{ border: 1, borderWidth: 2, justifyContent: "flex-start" }}
                          endIcon={index === codeCompletionSelectedIndex ? <ArrowOutwardIcon /> : undefined}>
                          {codeCompletion.descriptionFromCodeAcv || codeCompletion.lotLabel || codeCompletion.sousLotLabel}
                        </Button>
                      </Grid>
                      <Grid item xs={2}>
                        {!disabled && deleteButton(codeCompletion, index)}
                      </Grid>
                    </Grid>
                  </Stack>
                ))}
              </Stack>
            )}
          </Box>
        </Grid>
        <Grid item xs={7} sx={{ pr: 3, borderLeft: 1, minHeight: "350px" }}>
          <AffectMaterialInput
            openAssignMaterialModal={openAssignMaterialModal}
            disabled={codeCompletionSelectedIndex === -1 || disabled}
            removeMaterial={removeMaterial}
            selectedCode={getSelectedCodeCompletion()}
            variantCategory={variantCategory}
          />
          {getCurrentMaterial() && (
            <Button
              className="notranslate"
              onClick={() => setDisplayMaterialModalOpen(true)}
              style={{ textTransform: "none" }}
              endIcon={<ArrowOutwardIcon />}
              sx={{ mb: 2, border: 1, borderWidth: 2 }}>
              {getCurrentMaterial()?.fdesName}
            </Button>
          )}
          {codeCompletionSelectedIndex !== -1 && (
            <FormControlLabel
              control={<Checkbox disabled={disabled} checked={isReuseChecked()} onChange={handleReuseChange} />}
              label="Il s'agit d'un matériau de réemploi"
            />
          )}

          {getCurrentMaterial() && (
            <div onBlur={submitUpdateCompletion}>
              <NumberInput
                id="amount"
                label="Quantité"
                disabled={disabled}
                variant="outlined"
                size="small"
                decimalScale={2}
                value={getAmount()}
                handleChange={(value) => handleChangeNumber("amount", value)}
                unit={getLabelForIniesUnit(getCurrentMaterial()?.functionalUnit as string)}
              />
            </div>
          )}
          {codeCompletionSelectedIndex !== -1 && (
            <div onBlur={submitUpdateCompletion}>
              <TextField
                sx={{ mt: 2, width: "100%" }}
                name="hypothesis"
                value={getCurrentHypothesis()}
                onChange={(e) => handleChange(e)}
                label="Hypothèse BE"
                multiline
                rows={4}
                disabled={disabled}
              />
            </div>
          )}
        </Grid>
      </Grid>
    </>
  )
}
