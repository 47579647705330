import { useMemo } from "react"
import { appConstants } from "../../appConstants"
import { BSMaterialResult } from "../../dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { useHttp } from "../use-http"
import { BSCustomMaterialCreationDto } from "../../dto/beem-shot/BSMaterialResult/BSCustomMaterialCreationDto"
import { BSUpdateMaterialResult } from "../../dto/beem-shot/BSMaterialResult/BSUpdateMaterialResult"

type BimModelHook = {
  fetchBSMaterialResult(bsVariantId: string): Promise<BSMaterialResult[]>
  updateBSMaterialQuantities(bsVariantId: string, bsMaterialResultId: string, overriddenQuantities: string): Promise<void>
  resetBSMaterialQuantities(bsVariantId: string, bsMaterialResultId: string): Promise<void>
  addBSOverriddenMaterial(bsVariantId: string, bsUpdateMaterialResult: BSUpdateMaterialResult): Promise<void>
  putEnable(bsMaterialResultId: string, isEnable: boolean): Promise<BSMaterialResult>
  postCustomMaterial(bsMaterial: BSCustomMaterialCreationDto): Promise<BSMaterialResult>
  deleteCustomMaterial(bsMaterialResultId: string): Promise<Response>
}

export function useBSMaterialResults(): BimModelHook {
  const { get, post, put, deleteRequest } = useHttp()

  return useMemo(
    () => ({
      fetchBSMaterialResult(bsVariantId: string): Promise<BSMaterialResult[]> {
        return get(appConstants.apiEndpoints.BEEM_SHOT_MATERIAL_RESULT_LIST, [
          {
            key: "variantId",
            value: bsVariantId,
          },
        ]).then((response) => response.json())
      },
      updateBSMaterialQuantities(
        bsVariantId: string,
        bsMaterialResultId: string,
        overriddenQuantities: string
      ): Promise<void> {
        return post(appConstants.apiEndpoints.BEEM_SHOT_MATERIAL_UPDATE_RESULT_QUANTITIES, {}, [
          {
            key: "bsVariantId",
            value: bsVariantId,
          },
          {
            key: "bsMaterialResultId",
            value: bsMaterialResultId,
          },
          {
            key: "overriddenQuantities",
            value: overriddenQuantities.toString(),
          },
        ]).then(() => undefined)
      },
      resetBSMaterialQuantities(bsVariantId: string, bsMaterialResultId: string): Promise<void> {
        return post(appConstants.apiEndpoints.BEEM_SHOT_MATERIAL_RESET, {}, [
          {
            key: "bsVariantId",
            value: bsVariantId,
          },
          {
            key: "bsMaterialResultId",
            value: bsMaterialResultId,
          },
        ]).then(() => undefined)
      },
      addBSOverriddenMaterial(bsVariantId: string, bsUpdateMaterialResult: BSUpdateMaterialResult): Promise<void> {
        return post(appConstants.apiEndpoints.BEEM_SHOT_MATERIAL_ADD_OVERRIDDE, { ...bsUpdateMaterialResult }, [
          { key: "bsVariantId", value: bsVariantId },
        ]).then(() => undefined)
      },
      putEnable(bsMaterialResultId: string, isEnable: boolean): Promise<BSMaterialResult> {
        return put(appConstants.apiEndpoints.BEEM_SHOT_MATERIAL_RESULT_DISABLE, {
          id: bsMaterialResultId,
          enable: isEnable,
        }).then((response) => response.json())
      },
      postCustomMaterial(bsMaterial: BSCustomMaterialCreationDto): Promise<BSMaterialResult> {
        return post(appConstants.apiEndpoints.BEEM_SHOT_ADD_MATERIAL, bsMaterial).then((response) => response.json())
      },
      deleteCustomMaterial(bsMaterialCustomId: string): Promise<Response> {
        return deleteRequest(appConstants.apiEndpoints.BEEM_SHOT_DELETE_MATERIAL, [
          { key: "bsMaterialCustomId", value: bsMaterialCustomId },
        ])
      },
    }),
    [get, put, post, deleteRequest]
  )
}
