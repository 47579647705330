import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material"
import React, { FormEvent } from "react"
import { TitleFormTypography } from "../typography/title-form-typography"
import { SubmitButton } from "../buttons/SubmitButton/SubmitButton"
import CancelButton from "../buttons/CancelButton/CancelButton"

interface IProps {
  title: string
  open: boolean
  formContent: React.ReactNode
  formId: string
  isSubmitting: boolean
  cancelButtonLabel?: string
  submitButtonLabel?: string

  onClose(): void

  onSubmit(event: FormEvent<HTMLFormElement> | FormEvent<HTMLDivElement>): Promise<void>
}

export function BSFormDialog({
  open,
  title,
  formContent,
  formId,
  isSubmitting,
  cancelButtonLabel = "Annuler",
  submitButtonLabel = "Valider",
  onClose,
  onSubmit,
}: Readonly<IProps>): React.JSX.Element {
  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth
      onClose={onClose}
      sx={{
        "& .MuiPaper-root": {
          backgroundColor: "#FFFFFF",
        },
      }}>
      <DialogTitle>
        <TitleFormTypography label={title} />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} component="form" id={formId} onSubmit={onSubmit} pt={2}>
          {formContent}
        </Grid>
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={onClose} label={cancelButtonLabel} />
        <SubmitButton label={submitButtonLabel} formId={formId} isSubmitting={isSubmitting} />
      </DialogActions>
    </Dialog>
  )
}
