import React, { ChangeEvent, FormEvent, useContext, useState } from "react"

import { Button, Grid, Tooltip, Typography } from "@mui/material"
import { CustomBottomNavigation } from "../../components/buttons/navigate-button/CustomBottomNavigation"
import PhoneInput from "../../components/inputs/phone-input/phone-input"
import { BaseSelectInput } from "../../components/inputs/select-input/BaseSelectInput"
import { TextInput } from "../../components/inputs/text-input/text-input"
import { appConstants } from "../../core/appConstants"
import { UserContext, UserStore } from "../../core/context/user/user-context"
import { MyUserUpdateDto } from "../../core/dto/user/my-user-update-dto"
import { isPhoneValid, roleMyAccountSelectOptions } from "../../core/services/user-service"
import AccountPasswordFormField from "./account-password-form-field"

type UserForm = {
  email: string
  givenName: string
  familyName: string
  phone: string
  organizationName: string
}

export default function AccountInfosFormField(): React.JSX.Element {
  const { user, updateUserContext, userLoading } = useContext<UserStore>(UserContext)

  const [openResetPassword, setOpenResetPassword] = useState<boolean>(false)

  const [userForm, setUserForm] = useState<UserForm>({
    email: user?.email ?? "",
    givenName: user?.givenName ?? "",
    familyName: user?.familyName ?? "",
    phone: user?.phone ?? "",
    organizationName: user?.organizationName ?? "",
  })

  const [error, setError] = useState<Record<string, any>>({})

  function handleChange(event: ChangeEvent<HTMLInputElement>): void {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.value
    const name = target.id

    setUserForm({ ...userForm, [name]: value })
  }

  function onSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault()
    const isValid = validate()

    if (isValid && user?.cognitoUserId) {
      const userUpdateDto: MyUserUpdateDto = {
        cognitoUserId: user.cognitoUserId,
        organizationId: user.organizationId,
        email: userForm.email.toLowerCase(),
        givenName: userForm.givenName,
        familyName: userForm.familyName,
        phone: userForm.phone,
      }
      updateUserContext(userUpdateDto)
    }
  }

  function validate(): boolean {
    let isValid = true
    if (userForm.email === undefined || userForm.email === "") {
      setError({ ...error, email: "Ce champ est requis" })
      isValid = false
    } else if (!appConstants.regex.email.test(userForm.email)) {
      setError({ ...error, email: "L'adresse email ne respecte pas la forme classique : email@complement.fin" })
      isValid = false
    }

    if (userForm.givenName === undefined || userForm.givenName === "") {
      setError({ ...error, givenName: "Ce champ est requis" })
      isValid = false
    }

    if (userForm.familyName === undefined || userForm.familyName === "") {
      setError({ ...error, familyName: "Ce champ est requis" })
      isValid = false
    }

    if (userForm.phone === "") {
      setError({ ...error, phone: "Ce champ est requis" })
      isValid = false
    } else if (!isPhoneValid(userForm.phone)) {
      setError({
        ...error,
        phone:
          'Le numéro de téléphone doit être composé uniquement de chiffres et faire 10 caractères ou commencer par "+" et faire 12 caractères',
      })
      isValid = false
    }

    return isValid
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  function doNothing(selectedValue: string): void {}

  function handleClose(): void {
    setOpenResetPassword(false)
  }

  return (
    <>
      <Grid
        container
        component="form"
        id="account-info-form"
        onSubmit={onSubmit}
        spacing={2}
        borderRadius={4}
        sx={{ boxShadow: "2px 4px 20px 0px #0000000D", backgroundColor: "white", p: 4, pt: 1 }}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Informations personelles</Typography>
        </Grid>
        <Grid item xs={6}>
          <TextInput
            fieldName="familyName"
            label="Nom"
            handleChange={handleChange}
            form={userForm}
            errors={error}
            backgroundColor="#F5F5F5"
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            fieldName="givenName"
            label="Prénom"
            handleChange={handleChange}
            form={userForm}
            errors={error}
            backgroundColor="#F5F5F5"
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            fieldName="email"
            label="Votre adresse e-mail"
            handleChange={handleChange}
            form={userForm}
            errors={error}
            backgroundColor="#F5F5F5"
          />
        </Grid>
        <Grid item xs={12}>
          <PhoneInput
            form={userForm}
            error={error}
            handleChange={handleChange}
            required
            variant="outlined"
            fullWidth
            backgroundColor="#F5F5F5"
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            fieldName="organizationName"
            label="Organisation"
            handleChange={handleChange}
            form={userForm}
            errors={error}
            backgroundColor="#F5F5F5"
          />
        </Grid>

        <Grid item xs={6} />

        <Tooltip title="Vous pouvez modifier votre rôle dans l’onglet organisation.">
          <Grid item xs={6}>
            <BaseSelectInput
              id="role"
              mode="direct"
              disabled
              label="Rôle"
              selectedOption={user?.role}
              options={roleMyAccountSelectOptions}
              handleChange={doNothing}
              backgroundColor="#F5F5F5"
            />
          </Grid>
        </Tooltip>
        <Grid item xs={12}>
          <Typography variant="body2" fontSize={12} sx={{ color: "#8F8F8F" }}>
            Les champs précédés d’une astérisque sont obligatoires.
          </Typography>
        </Grid>
      </Grid>

      <Button onClick={() => setOpenResetPassword(true)}>Réinitialiser mon mot de passe</Button>

      <AccountPasswordFormField openResetPassword={openResetPassword} handleClose={handleClose} />

      <CustomBottomNavigation actionLabel="Enregistrer" formId="account-info-form" />
    </>
  )
}
