import HeightIcon from "@mui/icons-material/Height"
import NumbersIcon from "@mui/icons-material/Numbers"
import SquareIcon from "@mui/icons-material/Square"
import { Box, Grid, TextField, Typography } from "@mui/material"
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import ViewerIFC from "../../../../components/ifc-displayer/ViewerIFC"
import { NumberInput } from "../../../../components/inputs/number-input/NumberInput"
import { CodeExtrait } from "../../../../core/dto/code-extrait/code-extrait"
import { Quantity } from "../../../../core/dto/quantity"
import { FunctionalUnitEnum } from "../../../../core/enum/functionalUnitEnum"
import { UnitEnum } from "../../../../core/enum/unitEnum"
import { getLabelForIniesUnit } from "../../../../core/services/unit-service"
import CalculatorModal from "./Calculator/calculator-modal"

const styleData = {
  position: "",
  zIndex: "",
  width: "",
  height: "",
  left: "",
  top: "",
  backgroundColor: "",
}
const allKey = Object.keys(styleData) as (keyof typeof styleData)[]

type IProps = {
  selectedCodeExtrait: CodeExtrait
  setSelectedCodeExtrait: Dispatch<SetStateAction<CodeExtrait | undefined>>
  viewer: ViewerIFC | undefined
  sendCodeExtraitUpdate(): void
  ifcDisplayerRef: React.RefObject<HTMLDivElement>
  ifcDisplayerParentRef: React.MutableRefObject<HTMLElement>
  disabled: boolean
}

export default function ExtractedCodeCoreData({
  selectedCodeExtrait,
  setSelectedCodeExtrait,
  viewer,
  sendCodeExtraitUpdate,
  ifcDisplayerRef,
  ifcDisplayerParentRef,
  disabled,
}: Readonly<IProps>): React.JSX.Element {
  const [isCalculatorModalOpen, setIsCalculatorModalOpen] = useState<boolean>(false)
  const [calculatorResult, setCalculatorResult] = useState("")
  const parentRefCalculator = useRef<HTMLElement>()
  const initialProperty = useRef<Record<string, string> | undefined>(undefined)
  const [selectedQuantityField, setSelectedQuantityField] = useState<keyof Quantity>()

  useEffect(() => {
    if (!isCalculatorModalOpen && parentRefCalculator.current && initialProperty?.current && ifcDisplayerRef.current) {
      allKey.forEach((key) => {
        if (initialProperty?.current) ifcDisplayerParentRef.current.style[key] = initialProperty.current[key]
      })
      resizeViewer()
    }
  }, [isCalculatorModalOpen])

  function attachViewer(element: HTMLElement): void {
    if (element) {
      parentRefCalculator.current = element
      if (isCalculatorModalOpen && parentRefCalculator.current && ifcDisplayerParentRef?.current) {
        if (!initialProperty.current) {
          allKey.forEach((key) => {
            styleData[key] = ifcDisplayerParentRef.current.style[key]
          })
          initialProperty.current = styleData
        }
        ifcDisplayerParentRef.current.style.position = "fixed"
        ifcDisplayerParentRef.current.style.borderTopLeftRadius = "24px"
        ifcDisplayerParentRef.current.style.borderBottomLeftRadius = "24px"
        ifcDisplayerParentRef.current.style.zIndex = "1102"
        ifcDisplayerParentRef.current.style.width = `calc(80% - 400px)`
        ifcDisplayerParentRef.current.style.height = `90%`
        ifcDisplayerParentRef.current.style.backgroundColor = `white`
        ifcDisplayerParentRef.current.style.left = `10%`
        ifcDisplayerParentRef.current.style.top = `5%`
        ifcDisplayerParentRef.current.style.bottom = `5%`
        resizeViewer()
      }
    }
  }

  function resizeViewer(): void {
    if (viewer && ifcDisplayerParentRef.current) {
      viewer.manager.subsetsManager.isActive = !viewer.manager.subsetsManager.isActive
      viewer.manager.cameras.resizeViewer(
        ifcDisplayerParentRef.current.offsetWidth,
        ifcDisplayerParentRef.current.offsetHeight
      )
    }
  }

  function handleOpenCalculator(fieldName: keyof Quantity): void {
    setSelectedQuantityField(fieldName)
    if (selectedCodeExtrait) {
      setCalculatorResult(String(selectedCodeExtrait.overiddenQuantities[fieldName]))
    }
    setIsCalculatorModalOpen(true)
  }

  function handleCloseCalculatorModal(): void {
    setIsCalculatorModalOpen(false)
  }

  function handleSetCalculatorQty(): void {
    if (calculatorResult && selectedQuantityField && selectedCodeExtrait?.overiddenQuantities) {
      setSelectedCodeExtrait({
        ...selectedCodeExtrait,
        overiddenQuantities: { ...selectedCodeExtrait.overiddenQuantities, [selectedQuantityField]: calculatorResult },
      })
    }
    handleCloseCalculatorModal()
  }

  function getLabelName(toTranslate: UnitEnum | undefined): string {
    if (toTranslate === UnitEnum.SQUARE_METER) return "surface"
    if (toTranslate === UnitEnum.PER_UNIT) return "number"
    if (toTranslate === UnitEnum.METER) return "length"
    return ""
  }

  function handleChangeNumber(fieldName: string, newAmount: string): void {
    handleChangeCoreData({ target: { name: fieldName, value: newAmount } })
  }

  function handleChangeCoreData(e: { target: { name: string; value: string } }): void {
    if (selectedCodeExtrait) {
      const fieldName = e.target.name

      let newCodeExtrait: CodeExtrait
      if (fieldName === "descriptionBe") {
        const value: string = e.target.value

        newCodeExtrait = { ...selectedCodeExtrait, [fieldName]: value }
      } else {
        const value: number = parseFloat(e.target.value)
        const newQuantity = { ...selectedCodeExtrait.overiddenQuantities, [fieldName]: value }
        newCodeExtrait = { ...selectedCodeExtrait, overiddenQuantities: newQuantity }

        if (selectedCodeExtrait.sameValuesV1V2 && fieldName === selectedCodeExtrait.materialVariant1?.functionalUnit) {
          newCodeExtrait = {
            ...newCodeExtrait,
            amountVariant1: value,
            amountVariant2: value,
          }
        } else {
          if (getLabelName(selectedCodeExtrait?.materialVariant1?.functionalUnit) === fieldName) {
            newCodeExtrait = { ...newCodeExtrait, amountVariant1: value }
          }
          if (getLabelName(selectedCodeExtrait?.materialVariant2?.functionalUnit) === fieldName) {
            newCodeExtrait = { ...newCodeExtrait, amountVariant2: value }
          }
        }
      }

      setSelectedCodeExtrait(newCodeExtrait)
    }
  }

  return (
    <>
      <CalculatorModal
        isOpen={isCalculatorModalOpen}
        calculatorResult={calculatorResult}
        handleCloseModal={handleCloseCalculatorModal}
        handleSetCalculatorQty={handleSetCalculatorQty}
        setCalculatorResult={setCalculatorResult}
        attachViewer={attachViewer}
      />

      <Box component="div" display="flex" flexDirection="column" sx={{ padding: "13px", backgroundColor: "#ebebeb" }}>
        <Typography sx={{ textTransform: "uppercase", fontWeight: "bold" }}>paramètres extraits</Typography>
        <Grid container sx={{ marginLeft: "20px" }} rowGap={1}>
          <Grid item xs={12} sx={{ display: "flex", flexDirection: "row" }}>
            <Typography sx={{ fontWeight: "bold" }}>Code ACV :&nbsp;</Typography>
            <Typography>{selectedCodeExtrait?.code}</Typography>
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", flexDirection: "row" }}>
            <Typography sx={{ fontWeight: "bold" }}>Description du code :&nbsp;</Typography>
            <Typography className="notranslate">{selectedCodeExtrait?.descriptionFromCodeAcv}</Typography>
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", flexDirection: "row" }}>
            <Typography sx={{ fontWeight: "bold" }}>Description architecte :&nbsp;</Typography>
            <Typography className="notranslate">{selectedCodeExtrait?.descriptionFromBimModel}</Typography>
          </Grid>
          <Grid item>
            <TextField
              name="descriptionBe"
              label="Description BE"
              variant="filled"
              value={selectedCodeExtrait?.descriptionBe || ""}
              fullWidth
              onChange={handleChangeCoreData}
              onBlur={sendCodeExtraitUpdate}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", flexDirection: "row" }}>
            <Typography sx={{ fontWeight: "bold" }}>Usage :&nbsp;</Typography>
            <Typography className="notranslate">{selectedCodeExtrait?.lotLabel}</Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        component="div"
        display="flex"
        flexDirection="column"
        sx={{ padding: "13px", margin: "30px 0", backgroundColor: "#ebebeb" }}>
        <Typography sx={{ textTransform: "uppercase", fontWeight: "bold" }}>quantités extraites</Typography>
        <Box component="div" display="flex" flexDirection="column" sx={{ marginLeft: "20px" }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center">
              <NumbersIcon sx={{ mr: 1 }} />
              <Typography
                display="flex"
                flexDirection="row"
                alignItems="center"
                flex={2}
                sx={{ fontWeight: "bold", margin: "10px 0" }}>
                Nombre = {(selectedCodeExtrait?.extractedQuantities.number || 0).toFixed(2)}
                {`${getLabelForIniesUnit(FunctionalUnitEnum.PER_UNIT)}`}
              </Typography>
            </Box>
            <span>-</span>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
              <Typography display="flex" flexDirection="row" alignItems="center" flex={2} sx={{ fontWeight: "bold" }}>
                Quantité retenue :
              </Typography>
              <Box onBlur={sendCodeExtraitUpdate} sx={{ ml: "10px" }}>
                <NumberInput
                  id="number"
                  label=""
                  disabled={!selectedCodeExtrait?.overiddenQuantities || disabled}
                  value={selectedCodeExtrait?.overiddenQuantities?.number ?? 0}
                  size="small"
                  decimalScale={2}
                  variant="outlined"
                  handleChange={(value) => handleChangeNumber("number", value)}
                  backgroundColor="white"
                />
              </Box>
              {/*TODO: This is a calculator buttons. It needs to be fixed before they are uncommented */}
              {/*<IconButton*/}
              {/*  onClick={() => {*/}
              {/*    handleOpenCalculator('number')*/}
              {/*  }}>*/}
              {/*  <CalculateIcon sx={{ fontSize: '38px' }} />*/}
              {/*</IconButton>*/}
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center">
              <HeightIcon
                sx={{
                  mr: 1,
                }}
              />
              <Typography
                display="flex"
                flexDirection="row"
                alignItems="center"
                flex={2}
                sx={{ fontWeight: "bold", margin: "10px 0" }}>
                Hauteur = {(selectedCodeExtrait?.extractedQuantities.height ?? 0).toFixed(2)}
                {`${getLabelForIniesUnit(FunctionalUnitEnum.METER)}`}
              </Typography>
            </Box>
            <span>-</span>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
              <Typography
                display="flex"
                flexDirection="row"
                alignItems="center"
                flex={2}
                sx={{ fontWeight: "bold", margin: "10px 0" }}>
                Quantité retenue :
              </Typography>
              <Box onBlur={sendCodeExtraitUpdate} sx={{ ml: "10px" }}>
                <NumberInput
                  id="height"
                  label=""
                  disabled={!selectedCodeExtrait?.overiddenQuantities || disabled}
                  size="small"
                  variant="outlined"
                  decimalScale={2}
                  value={selectedCodeExtrait?.overiddenQuantities?.height ?? 0}
                  handleChange={(value) => handleChangeNumber("height", value)}
                  backgroundColor="white"
                />
              </Box>
              {/*TODO: This is a calculator buttons. It needs to be fixed before they are uncommented */}
              {/*<IconButton*/}
              {/*  onClick={() => {*/}
              {/*    handleOpenCalculator('height')*/}
              {/*  }}>*/}
              {/*  <CalculateIcon sx={{ fontSize: '38px' }} />*/}
              {/*</IconButton>*/}
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center">
              <HeightIcon sx={{ transform: "rotate(90deg)", mr: 1 }} />
              <Typography
                display="flex"
                flexDirection="row"
                alignItems="center"
                flex={2}
                sx={{ fontWeight: "bold", margin: "10px 0" }}>
                Longueur = {(selectedCodeExtrait?.extractedQuantities.length ?? 0).toFixed(2)}
                {`${getLabelForIniesUnit(FunctionalUnitEnum.METER)}`}
              </Typography>
            </Box>
            <span>-</span>

            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
              <Typography
                display="flex"
                flexDirection="row"
                alignItems="center"
                flex={2}
                sx={{ fontWeight: "bold", margin: "10px 0" }}>
                Quantité retenue :
              </Typography>
              <Box onBlur={sendCodeExtraitUpdate} sx={{ ml: "10px" }}>
                <NumberInput
                  id="length"
                  label=""
                  disabled={!selectedCodeExtrait?.overiddenQuantities || disabled}
                  value={selectedCodeExtrait?.overiddenQuantities?.length ?? 0}
                  size="small"
                  variant="outlined"
                  decimalScale={2}
                  handleChange={(value) => handleChangeNumber("length", value)}
                  backgroundColor="white"
                />
              </Box>
              {/*TODO: This is a calculator buttons. It needs to be fixed before they are uncommented */}
              {/*<IconButton*/}
              {/*  onClick={() => {*/}
              {/*    handleOpenCalculator('length')*/}
              {/*  }}>*/}
              {/*  <CalculateIcon sx={{ fontSize: '38px' }} />*/}
              {/*</IconButton>*/}
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center">
              <SquareIcon
                sx={{
                  mr: 1,
                }}
              />
              <Typography
                display="flex"
                flexDirection="row"
                alignItems="center"
                flex={2}
                sx={{ fontWeight: "bold", margin: "10px 0" }}>
                Surface = {(selectedCodeExtrait?.extractedQuantities.surface ?? 0).toFixed(2)}
                {`${getLabelForIniesUnit(FunctionalUnitEnum.SQUARE_METER)}`}
              </Typography>
            </Box>
            <span>-</span>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
              <Typography
                display="flex"
                flexDirection="row"
                alignItems="center"
                flex={2}
                sx={{ fontWeight: "bold", margin: "10px 0" }}>
                Quantité retenue :
              </Typography>
              <Box onBlur={sendCodeExtraitUpdate} sx={{ ml: "10px" }}>
                <NumberInput
                  id="surface"
                  label=""
                  disabled={!selectedCodeExtrait?.overiddenQuantities || disabled}
                  value={selectedCodeExtrait?.overiddenQuantities?.surface ?? 0}
                  size="small"
                  variant="outlined"
                  decimalScale={2}
                  handleChange={(value) => handleChangeNumber("surface", value)}
                  backgroundColor="white"
                />
              </Box>
              {/*TODO: This is a calculator buttons. It needs to be fixed before they are uncommented */}
              {/*<IconButton*/}
              {/*  onClick={() => {*/}
              {/*    handleOpenCalculator('surface')*/}
              {/*  }}>*/}
              {/*  <CalculateIcon sx={{ fontSize: '38px' }} />*/}
              {/*</IconButton>*/}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
