import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Tooltip } from "@mui/material"
import React, { FormEvent, useCallback, useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { NumberInput } from "../../../../../../components/inputs/number-input/NumberInput"
import { EnumRadioButton } from "../../../../../../components/inputs/radio-button-input/EnumRadioButton"
import { BSModelImportContext } from "../../../../../../core/context/beem-shot/BSBimModel/BSBimModelImportContext"
import { BSItemContext, dtoToForm, formToDto, IForm } from "../../../../../../core/context/beem-shot/BSItems/BSItemContext"
import { BSItem } from "../../../../../../core/dto/beem-shot/BSItem/BSItem"
import { BSMacroComponentType } from "../../../../../../core/dto/beem-shot/BSMacroComponent/BSMacroComponentType"
import { CodeExtrait } from "../../../../../../core/dto/code-extrait/code-extrait"
import { Quantity } from "../../../../../../core/dto/quantity"
import { calculTypeEnumLabelsOption } from "../../../../../../core/enum/beem-shot/CalculTypeEnum"
import { useBSItem } from "../../../../../../core/hooks/beem-shot/useBSItem"
import { useForm } from "../../../../../../core/hooks/form/use-form"
import { codeToKey } from "../../../../../../core/services/code-service"
import { identity } from "../../../../../../core/services/helper-service"
import { CodeDisplayer } from "../../../../components/BSBimModelExtractedCode/CodeDisplayer"
import { MacroComponentSelect } from "./MacroComponentSelect"

interface IProps {
  bsItem: BSItem
  expandedBSItem: string
  disabled: boolean

  handleExpandedBsItem(toExpand?: string): void
}

export function BSItemAccordion({
  bsItem,
  expandedBSItem,
  handleExpandedBsItem,
  disabled,
}: Readonly<IProps>): React.JSX.Element {
  const { bsProjectId } = useParams()

  const { updatBSItemFunction, selectedCodeExtrait, setSelectedCodeExtrait } = useContext(BSItemContext)
  const { viewer } = useContext(BSModelImportContext)

  const { getBSMacroComponentTypeList } = useBSItem()

  const [bsMacroComponentTypes, setBsMacroComponentTypes] = useState<BSMacroComponentType[]>([])

  useEffect(() => {
    if (bsProjectId) {
      getBSMacroComponentTypeList(bsItem.type).then((response) => {
        setBsMacroComponentTypes(response)
      })
    }
  }, [bsItem.type, bsProjectId, getBSMacroComponentTypeList])

  const submit = useCallback(
    (someForm: IForm) => updatBSItemFunction(formToDto(bsItem.id, someForm)),
    [bsItem.id, updatBSItemFunction]
  )

  const { form, handleSubmit, setForm } = useForm(bsItem, dtoToForm, [], submit)

  function handleFocusOut(): void {
    const e = { preventDefault: identity } as FormEvent<HTMLFormElement> | FormEvent<HTMLDivElement>
    handleSubmit(e)
  }

  function handleChangeNumber(fieldName: string, newAmount: string): void {
    handleChangeCoreData({ target: { name: fieldName, value: newAmount } })
  }

  function handleChangeCoreData(e: { target: { name: string; value: string } }): void {
    const fieldName = e.target.name

    if (form.codeExtrait) {
      const value: number = parseFloat(e.target.value)
      const newQuantity: Quantity = { ...form.codeExtrait?.overiddenQuantities, [fieldName]: value }
      const newCodeExtrait: CodeExtrait = { ...form.codeExtrait, overiddenQuantities: newQuantity }

      setForm({ ...form, codeExtrait: newCodeExtrait })
    }
  }

  function handleClickAcv(codeExtract: CodeExtrait, disableViewerHilight?: boolean): void {
    if (
      !disableViewerHilight &&
      `${codeExtract.code + codeExtract.occurence}` !==
        `${selectedCodeExtrait ? selectedCodeExtrait.code + selectedCodeExtrait.occurence : ""}`
    )
      viewer?.manager.subsetsManager.highlightCodeExtrait(codeExtract)
  }

  return (
    <Tooltip arrow placement="right" title={disabled ? "Veuillez activer cet élément avant de le modifier." : ""}>
      <Accordion
        id={bsItem.id}
        expanded={expandedBSItem === bsItem.id}
        disabled={disabled}
        onChange={(e, isClose) => {
          if (!isClose) {
            handleExpandedBsItem("")
            viewer?.manager.subsetsManager.restoreAllSubsetsMaterial()
            setSelectedCodeExtrait(undefined)
          } else {
            handleExpandedBsItem(bsItem.id)
            if (bsItem.codeExtrait) {
              handleClickAcv(bsItem.codeExtrait, false)
            }
          }
        }}
        TransitionProps={{ unmountOnExit: true }}
        sx={{
          borderRadius: "10px !important", // Parler du problème à Wanda
          backgroundColor: "#F5F8F8",
          boxShadow: "unset",
          border: () => {
            if (disabled) {
              return "3px solid #ECF3F3"
            } else if (expandedBSItem === bsItem.id) {
              return "3px solid #08CA8F"
            } else {
              return "3px solid #ECF3F3"
            }
          },
          my: 1,
          width: "100%",
          cursor: disabled ? "pointer" : "default",
        }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box
            id={bsItem.codeExtrait ? codeToKey(bsItem.codeExtrait) : ""}
            sx={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
            <CodeDisplayer
              codeTitle={bsItem.type.label}
              codeDescription={bsItem.codeExtrait?.descriptionFromBimModel}
              codeExtrait={bsItem.codeExtrait}
              codeColor={bsItem.codeExtrait?.color ?? "red"}
              showBorder={false}
            />
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}>
            <EnumRadioButton
              id="calculType"
              label="Données environnementales utilisées"
              value={form.calculType}
              options={calculTypeEnumLabelsOption}
              handleChange={(e) => {
                updatBSItemFunction(formToDto(form.id, form, e.target.value))
              }}
              flexDirection="column"
              alignItems="flex-start"
              disabled={disabled}
            />
            {bsItem.codeExtrait && (
              <Grid container rowSpacing={1} columnSpacing={1}>
                <Grid item xs={6}>
                  <NumberInput
                    id="number"
                    label="Nombre"
                    value={form.codeExtrait?.overiddenQuantities?.number ?? 0}
                    handleChange={(value) => {
                      handleChangeNumber("number", value)
                    }}
                    variant="outlined"
                    handleFocusOut={handleFocusOut}
                    disabled={disabled}
                  />
                </Grid>

                <Grid item xs={6}>
                  <NumberInput
                    id="length"
                    label="Longueur (m)"
                    value={bsItem.codeExtrait?.overiddenQuantities.length ?? 0}
                    variant="outlined"
                    handleChange={(value) => {
                      handleChangeNumber("length", value)
                    }}
                    handleFocusOut={handleFocusOut}
                    disabled={disabled}
                  />
                </Grid>

                <Grid item xs={6}>
                  <NumberInput
                    id="height"
                    label="Hauteur (m)"
                    value={bsItem.codeExtrait?.overiddenQuantities.height ?? 0}
                    variant="outlined"
                    handleChange={(value) => {
                      handleChangeNumber("height", value)
                    }}
                    handleFocusOut={handleFocusOut}
                    disabled={disabled}
                  />
                </Grid>

                <Grid item xs={6}>
                  <NumberInput
                    id="surface"
                    label="Surface (m²)"
                    value={bsItem.codeExtrait?.overiddenQuantities.surface ?? 0}
                    variant="outlined"
                    handleChange={(value) => {
                      handleChangeNumber("surface", value)
                    }}
                    handleFocusOut={handleFocusOut}
                    disabled={disabled}
                  />
                </Grid>
              </Grid>
            )}

            {bsMacroComponentTypes.map((bsMacroComponentType) => (
              <MacroComponentSelect
                key={bsMacroComponentType.name}
                bsMacroComponentType={bsMacroComponentType}
                bsItem={bsItem}
                disabled={disabled}
              />
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Tooltip>
  )
}
