import { Button, CircularProgress } from "@mui/material"
import React from "react"

type IProps = {
  label: string
  isSubmitting?: boolean
  isDisabled?: boolean
  fullWidth?: boolean
  onClick(): void
}

export function ValidateButton({
  label,
  isSubmitting,
  isDisabled = false,
  fullWidth,
  onClick,
}: Readonly<IProps>): React.JSX.Element {
  if (isSubmitting) {
    return <CircularProgress />
  } else {
    return (
      <Button
        variant="contained"
        color="primary"
        size="medium"
        onClick={onClick}
        disabled={isDisabled}
        fullWidth={fullWidth}>
        {label}
      </Button>
    )
  }
}
