import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import { Box, Card, CardContent, Grid, IconButton, Typography } from "@mui/material"
import React, { useCallback, useContext, useState } from "react"
import CancelButton from "../../../../buttons/CancelButton/CancelButton"
import { ValidateButton } from "../../../../buttons/ValidateButton/ValidateButton"
import { DefaultTypography } from "../../../../typography/default-typography"
import { appConstants } from "../../../../../core/appConstants"
import { BSMaterialResult } from "../../../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { FicheConfiguree } from "../../../../../core/dto/fiche-configuree/fiche-configuree"
import { IniesRecord } from "../../../../../core/dto/material/IniesRecord"
import { MaterialRecord } from "../../../../../core/dto/material/MaterialRecord"
import { DeclarationTypeEnum } from "../../../../../core/enum/declarationTypeEnum"
import { FunctionalUnitEnum } from "../../../../../core/enum/functionalUnitEnum"
import { ttbStatusToColor, ttbStatusToLabel } from "../../../../../core/enum/ttbStatus"
import { TypeMaterialEnum } from "../../../../../core/enum/typeMaterialEnum"
import { fromIniesId, toLabel } from "../../../../../core/enum/unitEnum"
import { formatToFrench, stringToDate } from "../../../../../core/services/date-service"
import { KPIFiche } from "./KPIFiche"
import { getDeclarationTypeLabel, getRecordId } from "../../../../../core/services/declaration-service"
import { WarningFunctionalUnitDialog } from "./WarningFunctionalUnitDialog"
import { getFunctionalUnit } from "../../../../../core/services/unit-service"
import { BSMaterialResultContext } from "../../../../../core/context/beem-shot/BSMaterialResult/BSMaterialResultContext"

interface IProps {
  selectedRow: IniesRecord | MaterialRecord | FicheConfiguree
  selectedBSMaterialResult: BSMaterialResult | undefined
  actualQuantity: number

  getTypologyBackground(typologie: DeclarationTypeEnum): string

  getTypologyTextColor(typologie: DeclarationTypeEnum): string

  handleCloseMaterialCardModal(): void

  onSelect(
    selectedRow: IniesRecord | MaterialRecord | FicheConfiguree,
    actualQuantities: number,
    type: TypeMaterialEnum
  ): void
}

export function BSIniesRecordCardInfo({
  selectedRow,
  getTypologyBackground,
  actualQuantity,
  handleCloseMaterialCardModal,
  getTypologyTextColor,
  selectedBSMaterialResult,
  onSelect,
}: Readonly<IProps>): React.JSX.Element {
  const { isSubmitting } = useContext(BSMaterialResultContext)

  const [openWarningFunctionnalUnit, setOpenWarningFunctionnalUnit] = useState<boolean>(false)

  function getUrl(): string {
    if (!(selectedRow instanceof IniesRecord)) {
      return ""
    }
    return `${appConstants.web.baseInie}?id=${selectedRow?.iniesId}`
  }

  function getAllParameters(): string {
    let chaineConcatenee = `${selectedRow.description || ""}`
    if (selectedRow instanceof MaterialRecord && selectedRow.parameters.length > 0) {
      chaineConcatenee += "<br/><br/><b> Paramètres : </b> <ul>"
      selectedRow.parameters.forEach((chaine) => {
        chaineConcatenee += ` <li><b> ${chaine.name} : </b>  ${chaine.value} ${toLabel(
          fromIniesId(chaine.paramUnitId)
        )}  </li>
      `
        return chaine
      })
      chaineConcatenee += "</ul>"
    }
    return chaineConcatenee
  }

  const functionalUnit: FunctionalUnitEnum =
    FunctionalUnitEnum[selectedRow.functionalUnit as unknown as keyof typeof FunctionalUnitEnum]

  const onSubmit = useCallback(
    (newQuantities: number): void => {
      if (selectedBSMaterialResult && selectedRow.functionalUnit !== selectedBSMaterialResult.ficheUnite) {
        setOpenWarningFunctionnalUnit(true)
      } else if (selectedRow instanceof IniesRecord) {
        onSelect(selectedRow, newQuantities, TypeMaterialEnum.INIES_MATERIAL)
        handleCloseMaterialCardModal()
      } else if (selectedRow instanceof FicheConfiguree) {
        onSelect(selectedRow, newQuantities, TypeMaterialEnum.FICHE_CONFIGURE)
        handleCloseMaterialCardModal()
      }
    },
    [handleCloseMaterialCardModal, onSelect, selectedBSMaterialResult, selectedRow]
  )

  const onSelectQuantity = useCallback(
    (newQuantity: number): void => {
      onSubmit(newQuantity)
    },
    [onSubmit]
  )

  return (
    <>
      <Card sx={{ width: 1100, background: "#ffffff", borderRadius: 4 }}>
        <CardContent>
          <Box component="form" display="flex" flexDirection="column">
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography
                component="span"
                sx={{
                  fontWeight: "bold",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}>
                {selectedRow instanceof IniesRecord && selectedRow.fdesName}{" "}
                {selectedRow instanceof IniesRecord && selectedRow.ttbStatus ? (
                  <Typography
                    component="span"
                    sx={{
                      fontWeight: "bold",
                      color: ttbStatusToColor(selectedRow.ttbStatus),
                    }}>
                    ({ttbStatusToLabel(selectedRow.ttbStatus)})
                  </Typography>
                ) : null}
                {selectedRow instanceof FicheConfiguree && selectedRow.name}
              </Typography>

              <IconButton sx={{ marginLeft: "auto" }} onClick={handleCloseMaterialCardModal}>
                <CloseOutlinedIcon />
              </IconButton>
            </Box>

            <Box borderRadius={4}>
              <Typography
                fontWeight={600}
                component="span"
                sx={{
                  display: "inline-block",
                  backgroundColor: getTypologyBackground(
                    selectedRow instanceof FicheConfiguree
                      ? DeclarationTypeEnum.FICHE_CONFIGUREE
                      : selectedRow.declarationType
                  ),
                  color: getTypologyTextColor(
                    selectedRow instanceof FicheConfiguree
                      ? DeclarationTypeEnum.FICHE_CONFIGUREE
                      : selectedRow.declarationType
                  ),
                  padding: 0.8,
                  borderRadius: 3,
                  fontSize: 12,
                }}>
                {selectedRow instanceof IniesRecord && getDeclarationTypeLabel(selectedRow?.declarationType)}
                {selectedRow instanceof FicheConfiguree && "Fiche configurée"}
              </Typography>
            </Box>

            <Box
              display="flex"
              flexDirection="row"
              py={1}
              alignItems="center"
              alignContent="center"
              columnGap={0.5}
              justifyItems="center">
              <DefaultTypography label="Modifiée le " color="#070F27" fontWeight={400} fontSize="11px" lineHeight="16.5px" />

              {!(selectedRow instanceof FicheConfiguree) && (
                <DefaultTypography
                  label={
                    selectedRow instanceof IniesRecord
                      ? ` ${formatToFrench(selectedRow?.lastIniesUpdate)} depuis la base INIES.`
                      : ` ${formatToFrench(selectedRow?.lastModifiedDate)} par ${selectedRow?.lastModifiedUserName}.`
                  }
                  color="#070F27"
                  fontWeight={500}
                  fontSize="11px"
                  lineHeight="16.5px"
                />
              )}

              {selectedRow instanceof FicheConfiguree && (
                <DefaultTypography
                  label={` ${formatToFrench(stringToDate(selectedRow?.lastModifiedDate))} par ${
                    selectedRow?.createdByUser
                  }.`}
                  color="#070F27"
                  fontWeight={500}
                  fontSize="11px"
                  lineHeight="16.5px"
                />
              )}
            </Box>

            <Grid container spacing={2} flexGrow={1} justifyContent="space-between" alignItems="center" width="100%" py={2}>
              <Grid item xs={3} display="flex" flexDirection="column">
                <KPIFiche label="Identifiant" value={getRecordId(selectedRow)} />
              </Grid>
              <Grid item xs={3} display="flex" flexDirection="column">
                <KPIFiche
                  label="Total impact selon RE2020"
                  value={`${(selectedRow?.re2020CarbonImpact ?? 0).toFixed(2)} kg eq CO₂/${getFunctionalUnit(selectedRow)}`}
                />
              </Grid>

              <Grid item xs={3} display="flex" flexDirection="column">
                <KPIFiche label="Unité fonctionnelle" value={functionalUnit} />
              </Grid>

              <Grid item xs={3} display="flex" flexDirection="column">
                <KPIFiche label="Durée de vie" value={selectedRow.referenceLifeTime.toString()} />
              </Grid>

              <Grid item xs={12} display="flex" flexDirection="column">
                <KPIFiche label="Caractéristiques" value={getAllParameters()} />
              </Grid>

              {selectedRow instanceof IniesRecord && (
                <Grid item xs={12} display="flex" flexDirection="column">
                  <DefaultTypography
                    label="Organisme déclarant"
                    fontSize="11px"
                    lineHeight="26.5px"
                    fontWeight={400}
                    color="#8398A6"
                  />
                  <DefaultTypography label={`${selectedRow.responsibleOrganism}`} fontSize="14px" lineHeight="24px" />
                </Grid>
              )}
            </Grid>
          </Box>

          <Grid container pt={1} columnSpacing={1}>
            <Grid item xs={8}>
              {selectedRow instanceof IniesRecord && (
                <Typography
                  component="a"
                  href={getUrl()}
                  target="_blank"
                  rel="noopener noreferrer"
                  fontSize="14px"
                  fontWeight={400}
                  sx={{ color: "#070F27" }}>
                  Consulter la base inies
                </Typography>
              )}
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="center">
              <CancelButton onClick={() => handleCloseMaterialCardModal()} label="Annuler" fullWidth />
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="center">
              <ValidateButton
                onClick={() => onSubmit(actualQuantity)}
                label="Utiliser"
                isSubmitting={isSubmitting}
                fullWidth
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <WarningFunctionalUnitDialog
        openWarningFunctionnalUnit={openWarningFunctionnalUnit}
        selectedBSMaterialResult={selectedBSMaterialResult}
        handleClose={() => setOpenWarningFunctionnalUnit(false)}
        selectedRow={selectedRow}
        isSubmitting={isSubmitting}
        actualQuantity={actualQuantity}
        onSubmit={onSelectQuantity}
      />
    </>
  )
}
