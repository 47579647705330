import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined"
import FolderCopyOutlinedIcon from "@mui/icons-material/FolderCopyOutlined"
import { Box } from "@mui/material"
import React, { useContext, useEffect } from "react"
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted"
import { pagesUrl } from "../../../core/appConstants"
import { BSProjectContext } from "../../../core/context/beem-shot/BSProject/BSProjectContext"
import { BSVariantContext } from "../../../core/context/beem-shot/BSVariant/BSVariantContext"
import { AppNavBarContext } from "../../../core/context/nav-bar/AppNavBarContext"
import { AppSideBarContext } from "../../../core/context/side-bar/side-bar-context"
import { BSVariantList } from "../BSVariantDetailsPage/components/BSVariantList"
import { CustomTabPanel, TabPanelProps } from "../../../components/tabs/CustomTabPanel"
import { TabEnum } from "../../../components/tabs/tabs"
import { BSDashboardVarianteTab } from "../BSVariantDetailsPage/BSDashboardVariantTab/BSDashboardVarianteTab"
import { CalculStatusEnum } from "../../../core/enum/calculStatusEnum"

export function BSProjectDetailsPage(): React.JSX.Element {
  const { bsProject } = useContext(BSProjectContext)
  const { bsVariants } = useContext(BSVariantContext)
  const { setShowAppSide } = useContext(AppSideBarContext)
  const { setTypeNavBar, setPreviousUrl, setStepHeader, setShowProjectStatus, setTitle, setPagination } =
    useContext(AppNavBarContext)

  const [tabs, setTabs] = React.useState<TabEnum>(TabEnum.BS_VARIANTS)

  useEffect(() => {
    setPreviousUrl(pagesUrl.BEEM_SHOT_HOME)
    setShowProjectStatus(true)
    setTitle(bsProject?.name ?? "")
    setPagination(["Tableau de bord", "Projets"])

    return () => {
      setShowProjectStatus(false)
      setTitle("BEEM SHOT")
    }
  }, [bsProject?.name, setPagination, setPreviousUrl, setShowProjectStatus, setStepHeader, setTitle, setTypeNavBar])

  useEffect(() => {
    setShowAppSide(true)
  }, [setShowAppSide])

  function handleChange(event: React.SyntheticEvent, newValue: TabEnum): void {
    setTabs(newValue)
  }

  const tabTest: TabPanelProps[] = [
    {
      id: TabEnum.BS_VARIANTS,
      Icon: FileCopyOutlinedIcon,
      label: "Variantes",
      chips: bsVariants.length.toString(),
      content: <BSVariantList />,
    },
    {
      id: TabEnum.BS_BIM_MODEL_POOL,
      Icon: FolderCopyOutlinedIcon,
      label: "Maquettes BIM",
      chips: undefined,
      content: <Box />,
      disabled: true,
      disabledText: "Fonctionnalité à venir",
    },
    {
      id: TabEnum.BS_COMPARE_VARIANTES,
      Icon: FormatListBulletedIcon,
      label: "Comparaison Variantes",
      chips: undefined,
      content: <BSDashboardVarianteTab />,
      disabled: bsVariants.filter((bsVariant) => bsVariant.calculStatus === CalculStatusEnum.READY).length < 2,
      disabledText: "Vous n'avez pas encore de variante",
    },
  ]

  return (
    <Box display="flex" flexDirection="column" height="78vh">
      <CustomTabPanel panels={tabTest} currentTabId={tabs} handleChange={handleChange} />
    </Box>
  )
}
