import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import React, { useCallback, useMemo } from "react"
import { WithId } from "../../../core/type/WithId"

export type SelectOption<T extends WithId> = {
  value: T
  label: string
}

const defaultOption: SelectOption<WithId> = {
  // Must put a value. 'undefined' and "" does not work.
  // 'undefined' is for uncontrolled components. "",
  // <select> do not handle it correctly and the options appears empty.
  // null is not accepted by <select>
  value: { id: "empty" },
  label: "-- Choisissez une option --",
}

function isEmpty(option: WithId | undefined): boolean {
  return !option || option?.id === "empty"
}

interface IProps<T extends WithId> {
  id: string // it must be a key of the form
  label: string
  selectedOption: T | undefined
  options: SelectOption<T>[]
  isBeemShot?: boolean
  errors?: Record<string, string | undefined>
  disabled?: boolean
  enableNoneOption?: boolean
  keepNoneOptionAfterSelect?: boolean

  handleChange(selectedValue: T | undefined): void
}

export function SelectObjectInput<T extends WithId>({
  id,
  label,
  selectedOption,
  options,
  handleChange,
  disabled = false,
  errors = {},
  enableNoneOption = true,
  keepNoneOptionAfterSelect = false, // Make the none option disappear after first selection. Only relevant when enableNoneOption = false
}: Readonly<IProps<T>>): React.JSX.Element {
  const initOptions: () => SelectOption<T | WithId>[] = useCallback(() => {
    if (enableNoneOption && options === undefined) {
      return [defaultOption]
    } else if (options === undefined) {
      return []
    } else if (enableNoneOption && (keepNoneOptionAfterSelect || isEmpty(selectedOption))) {
      return [defaultOption, ...options]
    } else {
      return [...options]
    }
  }, [enableNoneOption, keepNoneOptionAfterSelect, options, selectedOption])
  const actualOptions = useMemo<SelectOption<T | WithId>[]>(() => initOptions(), [initOptions])

  const setValue = useCallback(
    (value: T | WithId): void => {
      if (handleChange) {
        value.id === "" ? handleChange(undefined) : handleChange(value as T)
      }
    },
    [handleChange]
  )

  const selectOption = useCallback((): T | WithId => {
    if (!selectedOption) {
      return defaultOption.value
    }
    if (selectedOption) {
      return selectedOption
    } else if (options?.length > 0 && options[0]) {
      setValue(options[0].value)
      return options[0].value
    } else {
      return defaultOption.value
    }
  }, [selectedOption, options, setValue])

  const actualSelected = useMemo(() => selectOption(), [selectOption])

  function handleChangeLocal(event: SelectChangeEvent): void {
    const newSelectedValue = fromId(event.target.value)
    setValue(newSelectedValue)
  }

  function fromId(valueId: string | ""): T | WithId {
    const selected = actualOptions.find((option) => option.value?.id === valueId)

    // It should always have found something. But the case undefined has to be handled
    return selected ? selected.value : defaultOption.value
  }

  return (
    <FormControl
      disabled={disabled}
      fullWidth
      sx={{
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            border: "none",
          },
        },
      }}>
      <InputLabel>{label}</InputLabel>
      <Select
        id={id}
        labelId={`${label}-id-label`}
        value={actualSelected.id}
        onChange={handleChangeLocal}
        label={label}
        sx={{ borderRadius: 3, backgroundColor: disabled ? "#EBEBEB" : "#F5F5F5" }}>
        {actualOptions.map((option) => (
          <MenuItem
            key={option.value.id}
            value={option.value.id}
            disabled={option.value.id === "empty" && !keepNoneOptionAfterSelect}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error>{errors[id]}</FormHelperText>
    </FormControl>
  )
}
