import React, { useCallback, useContext, useMemo, useState } from "react"
import { BSCustomMaterialDialog } from "./BSCustomMaterialDialog"
import { BSItem } from "../../../../../../../core/dto/beem-shot/BSItem/BSItem"
import { Children } from "../../../../../../../components/miscellianous/children"
import { BSMaterialResult } from "../../../../../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { SelectionContext } from "../../context/SelectionContext"

export const BSCustomMaterialDialogContext = React.createContext<CustomMaterialDialogStore>({} as CustomMaterialDialogStore)

export function BSCustomMaterialDialogContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const [isOpen, setIsOpen] = useState(false)
  const { selectedBSItem, setSelectedBSItem, setSelectedBSMaterialResult } = useContext(SelectionContext)

  const onClose = useCallback((): void => {
    setIsOpen(false)
  }, [])

  const openDialog = useCallback(
    (newBSItem: BSItem | undefined, bsMaterialResult: BSMaterialResult | undefined): void => {
      if (newBSItem) {
        setSelectedBSItem(newBSItem)
        setSelectedBSMaterialResult(bsMaterialResult)
        setIsOpen(true)
      }
    },
    [setSelectedBSItem, setSelectedBSMaterialResult]
  )

  const customMaterialDialogStore = useMemo(() => ({ openCustomMaterialDialog: openDialog }), [openDialog])

  return (
    <BSCustomMaterialDialogContext.Provider value={customMaterialDialogStore}>
      {selectedBSItem && <BSCustomMaterialDialog isOpen={isOpen} handleClose={onClose} />}
      {children}
    </BSCustomMaterialDialogContext.Provider>
  )
}

export type CustomMaterialDialogStore = {
  openCustomMaterialDialog(bsItem: BSItem | undefined, bsMaterialResult: BSMaterialResult | undefined): void
}
