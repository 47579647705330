import React, { useCallback, useContext } from "react"
import { ConfirmationDialog } from "../../../../../../../components/dialog/confirmation-dialog"
import { SelectionContext } from "../../context/SelectionContext"
import { BSVariantContext } from "../../../../../../../core/context/beem-shot/BSVariant/BSVariantContext"
import { BSMaterialResultContext } from "../../../../../../../core/context/beem-shot/BSMaterialResult/BSMaterialResultContext"

interface IProps {
  isOpen: boolean
  handleClose(): void
}

export function BSConfirmResetDialog({ isOpen, handleClose }: Readonly<IProps>): React.JSX.Element {
  const { resetMaterial } = useContext(BSMaterialResultContext)
  const { selectedVariant } = useContext(BSVariantContext)
  const { selectedBSMaterialResult } = useContext(SelectionContext)

  const handleResetMaterial = useCallback((): Promise<void> => {
    if (selectedBSMaterialResult?.id && selectedVariant?.id) {
      return resetMaterial(selectedVariant?.id, selectedBSMaterialResult.id).then(() => handleClose())
    }
    return Promise.resolve()
  }, [handleClose, resetMaterial, selectedBSMaterialResult, selectedVariant])

  return (
    <ConfirmationDialog
      id="reset-bs-material-result"
      title="Confirmation de la suppression"
      contentText="Êtes-vous sûr de vouloir rétablir les réglages par défaut ?"
      handleClose={handleClose}
      action={handleResetMaterial}
      open={isOpen}
    />
  )
}
