import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Children } from "../../../../components/miscellianous/children"
import { RseeProjectCardDto } from "../../../dto/rsee/rsee-project-card"
import { ProjectStatusEnum } from "../../../enum/projectStatusEnum"
import { useRsee } from "../../../hooks/rsee/use-rsee"

export const RseeProjectListContext = React.createContext<RseeProjectListStore>({} as RseeProjectListStore)

export const RSEE_PROJECT_ITEMS_PER_PAGE = 6

export function RseeProjectListContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const { fetchProjectCards } = useRsee()

  const [rseeProjectList, setRseeProjectList] = useState<RseeProjectCardDto[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isInitialized, setIsInitialized] = useState<boolean>(false)
  const [statusFilter, setStatusFilter] = useState<ProjectStatusEnum>(ProjectStatusEnum.ALL)

  const [projectCount, setProjectCount] = useState<number>(0)

  const [lastProjectId, setLastProjectId] = useState<string | undefined>(undefined)

  const [searchState, setSearchState] = useState<string>("")

  const [page, setPage] = useState(1)
  const [fetchOnlyMyRseeProject, setFetchOnlyMyRseeProject] = useState<boolean>(true)

  const isOneProjectCreated = useRef<boolean>(false)

  const fetchRseeProjectCard = useCallback(
    (actualPage: number, currentStatusFilter: ProjectStatusEnum, limit = RSEE_PROJECT_ITEMS_PER_PAGE) => {
      setIsLoading(true)
      return fetchProjectCards(fetchOnlyMyRseeProject, "", false, currentStatusFilter, actualPage, limit, searchState)
        .then((newBSProjectList) => {
          setRseeProjectList(newBSProjectList.projectCardDtoList)
          setProjectCount(newBSProjectList.projectCount)
          if (!isOneProjectCreated.current && newBSProjectList.projectCardDtoList.length > 0) {
            isOneProjectCreated.current = true
          }

          if (newBSProjectList.projectCardDtoList?.length > 0) {
            setLastProjectId(newBSProjectList?.projectCardDtoList[newBSProjectList.projectCardDtoList.length - 1].id)
          }
        })
        .finally(() => {
          setIsLoading(false)
          setIsInitialized(true)
        })
    },
    [fetchOnlyMyRseeProject, fetchProjectCards, searchState]
  )

  const refreshAllRseeProjectCard = useCallback(() => {
    fetchRseeProjectCard(page, statusFilter)
  }, [fetchRseeProjectCard, page, statusFilter])

  useEffect(() => {
    refreshAllRseeProjectCard()
  }, [page, refreshAllRseeProjectCard, statusFilter])

  const triggerSearch = useCallback((): void => {
    if (page !== 1) {
      // Updating the page will trigger a refresh by the useEffect above
      setPage(1)
    } else {
      fetchRseeProjectCard(1, statusFilter)
    }
  }, [fetchRseeProjectCard, page, statusFilter])

  const rseeProjectListStore = useMemo(
    () => ({
      rseeProjectList,
      setRseeProjectList,
      isLoading,
      setIsLoading,
      statusFilter,
      setStatusFilter,
      isOneProjectCreated,
      lastProjectId,
      projectCount,
      searchState,
      setSearchState,
      page,
      setPage,
      fetchOnlyMyRseeProject,
      setFetchOnlyMyRseeProject,
      isInitialized,
      triggerSearch,
      refreshAllRseeProjectCard,
    }),
    [
      fetchOnlyMyRseeProject,
      isLoading,
      lastProjectId,
      page,
      projectCount,
      rseeProjectList,
      searchState,
      statusFilter,
      isInitialized,
      triggerSearch,
      refreshAllRseeProjectCard,
    ]
  )
  return <RseeProjectListContext.Provider value={rseeProjectListStore}>{children}</RseeProjectListContext.Provider>
}

export type RseeProjectListStore = {
  rseeProjectList: RseeProjectCardDto[]
  setRseeProjectList: Dispatch<SetStateAction<RseeProjectCardDto[]>>
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
  statusFilter: ProjectStatusEnum
  setStatusFilter: Dispatch<SetStateAction<ProjectStatusEnum>>
  isOneProjectCreated: React.MutableRefObject<boolean>
  lastProjectId: string | undefined
  projectCount: number
  searchState: string
  setSearchState: Dispatch<SetStateAction<string>>
  page: number
  setPage: Dispatch<SetStateAction<number>>
  fetchOnlyMyRseeProject: boolean
  setFetchOnlyMyRseeProject: Dispatch<SetStateAction<boolean>>
  isInitialized: boolean
  triggerSearch(): void
  refreshAllRseeProjectCard(): void
}
