import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { Dispatch, SetStateAction, useCallback, useContext, useState } from "react"
import CancelButton from "../../../../../components/buttons/CancelButton/CancelButton"
import { ValidateButton } from "../../../../../components/buttons/ValidateButton/ValidateButton"
import { SuccessContext } from "../../../../../components/layout/success-snackbar"
import {
  dtoToForm,
  IForm,
  RseeProjectContext,
  updateFormToDto,
} from "../../../../../core/context/beem-pilot/rsee/rsee-project-context"
import { ProjectStatusEnum, projectStatusEnumLabels } from "../../../../../core/enum/projectStatusEnum"
import { useForm } from "../../../../../core/hooks/form/use-form"
import { enumToSelectOptions } from "../../../../../core/services/helper-service"
import { BaseSelectInput } from "../../../../../components/inputs/select-input/BaseSelectInput"

type IProps = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export function UpdateRseeProjectStatusDialog({ open, setOpen }: Readonly<IProps>): React.JSX.Element {
  const { rseeProject, updateProject } = useContext(RseeProjectContext)
  const openSuccessSnackbar = useContext(SuccessContext)

  const [error, setError] = useState<Record<string, string | undefined>>({})
  const [rseeNameToDelete, setRseeNameToDelete] = useState<string>("")
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  function onClose(): void {
    resetForm()
    setOpen(false)
  }

  const submit: (form: IForm) => Promise<any> = useCallback(
    (form: IForm) => {
      if (rseeProject?.id) {
        setIsSubmitting(true)
        return updateProject(updateFormToDto(form)).then(() => {
          onClose()
        })
      }
      return Promise.resolve()
    },
    [rseeProject?.id, updateProject]
  )

  const { form, handleChange, resetForm } = useForm(rseeProject, dtoToForm, [], submit)

  return (
    <Dialog open={open} onClose={() => onClose()} fullWidth maxWidth="md">
      <DialogTitle>{`Mise à jour du status de "${rseeProject?.projectName}"`}</DialogTitle>
      <DialogContent>
        <DialogContentText id="updateRseeProjectStatus">
          Modifiez l'état de votre projet selon son avancement. Veuillez noter qu'en passant au statut "Archivé" ou
          "Terminé", l'accès sera limité à la lecture seule, et aucune modification ne sera possible.
          <BaseSelectInput
            id="projectStatus"
            label="Statut"
            selectedOption={form.projectStatus}
            options={enumToSelectOptions(ProjectStatusEnum, projectStatusEnumLabels).filter(
              (selectedOption) =>
                selectedOption.value !== ProjectStatusEnum.DELETED && selectedOption.value !== ProjectStatusEnum.ALL
            )}
            mode="event"
            handleEventChange={handleChange}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={onClose} label="Annuler" />
        <ValidateButton
          onClick={() => {
            if (rseeProject) {
              updateProject(updateFormToDto(form))
                .then(() => {
                  onClose()
                  openSuccessSnackbar("Le statut du projet a bien été modifié")
                })
                .finally(() => {
                  onClose()
                })
            }
          }}
          label="Valider"
        />
      </DialogActions>
    </Dialog>
  )
}
