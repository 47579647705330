import React, { ChangeEvent, useCallback, useContext, useMemo } from "react"
import { CircularProgress } from "@mui/material"
import { BSMaterialResult } from "../../../../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { BSMaterialResultContext } from "../../../../../../core/context/beem-shot/BSMaterialResult/BSMaterialResultContext"
import { CheckboxInputNoLabel } from "../../../../../../components/inputs/checkbox-input/CheckboxInputNoLabel"

interface IProps {
  bsMaterialResult: BSMaterialResult
}

export function DisableMaterialInput({ bsMaterialResult }: IProps): React.JSX.Element {
  const { setEnable, materialUpdatingDisable } = useContext(BSMaterialResultContext)

  const bsMaterialResultId: string = useMemo(
    () => bsMaterialResult.id ?? "", // bsMaterialResult.id is never undefined here
    [bsMaterialResult.id]
  )

  const handleSetDisableFactory = useCallback(
    (materialToDisable: BSMaterialResult) =>
      (e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.checked
        if (materialToDisable?.id) {
          setEnable(materialToDisable.id, value)
        }
      },
    [setEnable]
  )
  return materialUpdatingDisable[bsMaterialResultId] ? (
    <CircularProgress />
  ) : (
    <CheckboxInputNoLabel
      id={bsMaterialResultId}
      checked={bsMaterialResult.enable}
      handleChange={handleSetDisableFactory(bsMaterialResult)}
    />
  )
}
