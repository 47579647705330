import { Button, CircularProgress } from "@mui/material"
import React from "react"
import { Box } from "@mui/system"

interface IProps {
  label: string
  formId: string
  isSubmitting: boolean
  minWidth?: string
}

export function SubmitButton({ label, formId, isSubmitting, minWidth }: IProps): React.JSX.Element {
  return !isSubmitting ? (
    <Button variant="contained" type="submit" sx={{ minWidth: { minWidth } }} form={formId}>
      {label}
    </Button>
  ) : (
    <Box sx={{ pl: 5, pr: 5 }}>
      <CircularProgress />
    </Box>
  )
}
