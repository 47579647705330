import React, { useMemo, useState } from "react"
import { BSItem } from "../../../../../../core/dto/beem-shot/BSItem/BSItem"
import { BSMaterialResult } from "../../../../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { Children } from "../../../../../../components/miscellianous/children"

export const SelectionContext = React.createContext<SelectionStore>({} as SelectionStore)

export function SelectionContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [selectedBSItem, setSelectedBSItem] = useState<BSItem | undefined>()
  const [selectedBSMaterialResult, setSelectedBSMaterialResult] = useState<BSMaterialResult | undefined>()

  const selectionStore = useMemo(
    () => ({
      isSubmitting,
      selectedBSItem,
      selectedBSMaterialResult,
      setSelectedBSItem,
      setSelectedBSMaterialResult,
      setIsSubmitting,
    }),
    [isSubmitting, selectedBSItem, selectedBSMaterialResult]
  )
  return <SelectionContext.Provider value={selectionStore}>{children}</SelectionContext.Provider>
}

export type SelectionStore = {
  selectedBSItem: BSItem | undefined
  selectedBSMaterialResult: BSMaterialResult | undefined
  isSubmitting: boolean
  setSelectedBSItem: React.Dispatch<React.SetStateAction<BSItem | undefined>>
  setSelectedBSMaterialResult: React.Dispatch<React.SetStateAction<BSMaterialResult | undefined>>
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>
}
