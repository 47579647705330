import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted"
import { Box } from "@mui/material"
import React, { useContext, useEffect, useMemo } from "react"
import { CustomTabPanel, TabPanelProps } from "../../../components/tabs/CustomTabPanel"
import { pagesUrl } from "../../../core/appConstants"
import BSModelInvitationContextProvider from "../../../core/context/beem-shot/BSBimModel/BSModelInvitationContextProvider"
import { BSProjectContext } from "../../../core/context/beem-shot/BSProject/BSProjectContext"
import { BSVariantContext } from "../../../core/context/beem-shot/BSVariant/BSVariantContext"
import { AppNavBarContext } from "../../../core/context/nav-bar/AppNavBarContext"
import { AppSideBarContext } from "../../../core/context/side-bar/side-bar-context"
import { CalculStatusEnum } from "../../../core/enum/calculStatusEnum"
import { ProjectStatusEnum } from "../../../core/enum/projectStatusEnum"
import { resolveUrl } from "../../../core/services/http-service"
import { BSVariantDetail } from "../components/BSVariantDetail"
import { BSDashboard } from "./BSDashboardVariantTab/BSDashboard"
import { TabEnum } from "../../../components/tabs/tabs"
import MaterialLibDeclarationTypeContextProvider from "../../../core/context/material/material-lib-declaration-type-context"
import MaterialLibFilterContextProvider from "../../../core/context/material/material-lib-filter-context"
import MaterialLibSearchContextProvider from "../../../core/context/material/material-lib-search-context"
import MaterialLibContextProvider from "../../../core/context/material/material-lib-context"
import IniesRecordContextProvider from "../../../core/context/inies-context"
import { BSCustomizationTab } from "./BSCustomizationTab/BSCustomizationTab"

function getStoredTab(): TabEnum {
  const currentTab: string | null = localStorage.getItem("activeTab")

  if (currentTab !== null) {
    const tabValue = parseInt(currentTab, 10)
    if (Object.values(TabEnum).includes(tabValue)) {
      return tabValue as TabEnum
    }
  }

  return TabEnum.BS_PROJECT
}

function storeTab(tab: TabEnum | undefined): void {
  if (tab) {
    localStorage.setItem("activeTab", tab.toString())
  } else {
    localStorage.removeItem("activeTab")
  }
}

export function BSVariantDetailPage(): React.JSX.Element {
  const { setShowAppSide } = useContext(AppSideBarContext)
  const { setTypeNavBar, setPreviousUrl, setStepHeader, setShowProjectStatus, setTitle, setPagination } =
    useContext(AppNavBarContext)
  const { bsProject } = useContext(BSProjectContext)
  const { selectedVariant } = useContext(BSVariantContext)

  const [currentTabId, setCurrentTabId] = React.useState<TabEnum>(getStoredTab)

  useEffect(() => {
    storeTab(currentTabId)
    return () => storeTab(undefined)
  }, [currentTabId])

  useEffect(() => {
    setPreviousUrl(resolveUrl(pagesUrl.BEEM_SHOT_PROJECTS_DETAIL, [bsProject?.id]))
    setShowProjectStatus(true)
    setTitle(selectedVariant?.name ?? "")
    setPagination(["Tableau de bord", "Projets", "Variante"])

    return () => {
      setShowProjectStatus(false)
      setTitle("BEEM SHOT")
    }
  }, [
    bsProject?.name,
    setPreviousUrl,
    setShowProjectStatus,
    setStepHeader,
    setTitle,
    setTypeNavBar,
    bsProject?.id,
    selectedVariant?.name,
    setPagination,
  ])

  useEffect(() => {
    setShowAppSide(true)
  }, [setShowAppSide, selectedVariant])

  function handleChange(event: React.SyntheticEvent, newValue: TabEnum): void {
    setCurrentTabId(newValue)
  }

  const tabTest = useMemo((): TabPanelProps[] => {
    const panels: TabPanelProps[] = []

    panels.push(
      {
        id: TabEnum.BS_PROJECT,
        Icon: FormatListBulletedIcon,
        label: "Général",
        chips: undefined,
        content: (
          <BSModelInvitationContextProvider>
            <BSVariantDetail handleChange={handleChange} isWritingMode />
          </BSModelInvitationContextProvider>
        ),
      },
      {
        id: TabEnum.TABLEAU_DE_BORD,
        Icon: FormatListBulletedIcon,
        label: "Tableau de bord",
        chips: undefined,
        content: <BSDashboard />,
        disabled: selectedVariant?.calculStatus !== CalculStatusEnum.READY,
        disabledText: "Vous n'avez pas encore lancé de calcul",
      }
    )

    if (selectedVariant?.calculStatus === CalculStatusEnum.READY) {
      panels.push({
        id: TabEnum.BS_DETAIL_CALCUL,
        Icon: FormatListBulletedIcon,
        label: "Personnalisation",
        chips: undefined,
        content: (
          <MaterialLibDeclarationTypeContextProvider>
            <MaterialLibFilterContextProvider>
              <MaterialLibSearchContextProvider>
                <MaterialLibContextProvider>
                  <IniesRecordContextProvider>
                    <BSCustomizationTab />
                  </IniesRecordContextProvider>
                </MaterialLibContextProvider>
              </MaterialLibSearchContextProvider>
            </MaterialLibFilterContextProvider>
          </MaterialLibDeclarationTypeContextProvider>
        ),
        disabled: selectedVariant?.calculStatus !== CalculStatusEnum.READY,
        disabledText: "Vous n'avez pas encore lancé de calcul",
      })
    }

    return panels
  }, [selectedVariant?.calculStatus])

  return (
    <Box>
      {bsProject && bsProject.status !== ProjectStatusEnum.IN_PROGRESS && (
        <Box
          display="flex"
          justifyContent="center"
          sx={{
            color: "white",
            borderRadius: 4,
            backgroundColor: "#070F27",
          }}>
          Mode lecture
        </Box>
      )}
      <CustomTabPanel panels={tabTest} currentTabId={currentTabId} handleChange={handleChange} />
    </Box>
  )
}
