import React, { ChangeEvent } from "react"
import { NumberInput } from "./NumberInput"

interface IProps {
  id: string
  label: string
  value: number | undefined
  errors?: Record<string, string | undefined>
  decimalScale?: number
  unit?: string
  disabled?: boolean
  handleChange?(event: ChangeEvent<HTMLInputElement>): void
}

export function BSNumberInput({
  id,
  label,
  value,
  errors,
  decimalScale = 2,
  unit,
  handleChange,
  disabled = false,
}: Readonly<IProps>): React.JSX.Element {
  return (
    <NumberInput
      id={id}
      label={label}
      value={value}
      decimalScale={decimalScale}
      errors={errors}
      unit={unit}
      handleEventChange={handleChange}
      disabled={disabled}
      variant="outlined"
      backgroundColor="#F5F5F5"
      mode="event"
      hideBorder
      borderRadius={3}
    />
  )
}
