import React, { useCallback, useContext } from "react"
import { ConfirmationDialog } from "../../../../../../../components/dialog/confirmation-dialog"
import { BSMaterialResultContext } from "../../../../../../../core/context/beem-shot/BSMaterialResult/BSMaterialResultContext"
import { SelectionContext } from "../../context/SelectionContext"

interface IProps {
  isOpen: boolean
  handleClose(): void
}

export function BSDeleteCustomDialog({ isOpen, handleClose }: Readonly<IProps>): React.JSX.Element {
  const { deleteMaterial } = useContext(BSMaterialResultContext)
  const { selectedBSMaterialResult } = useContext(SelectionContext)

  const handleDeleteMaterial = useCallback((): Promise<void> => {
    if (selectedBSMaterialResult) {
      return deleteMaterial(selectedBSMaterialResult).then(() => {
        handleClose()
      })
    }
    return Promise.resolve()
  }, [deleteMaterial, handleClose, selectedBSMaterialResult])

  return (
    <ConfirmationDialog
      id="delete-bs-material-result"
      title="Confirmation de la suppression"
      contentText="Êtes-vous sûr de vouloir supprimer cet élément ?"
      handleClose={handleClose}
      action={handleDeleteMaterial}
      open={isOpen}
    />
  )
}
