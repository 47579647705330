import { Box, Button, CircularProgress, Container, Grid, Paper, TextField, Typography } from "@mui/material"
import React, { FormEvent, useContext, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { adminPagesUrl } from "../../../core/appConstants"
import { AdminOrganizationContext } from "../../../core/context/organization/admin-organization-context"
import { DomainNameValidatorContext } from "../../../core/context/organization/domain-name-validator-context"
import { User } from "../../../core/dto/user/user"
import { UserForm } from "../../../core/dto/user/user-form"
import { OrganizationRoleEnum } from "../../../core/enum/user/organization-role-enum"
import { resolveUrl } from "../../../core/services/http-service"
import { isEmailValid, isPhoneValid } from "../../../core/services/user-service"
import PhoneInput from "../../inputs/phone-input/phone-input"
import { BaseSelectInput, SelectOption } from "../../inputs/select-input/BaseSelectInput"

type IProps = {
  onSubmit(userForm: UserForm): Promise<void>
  selectOptions: SelectOption<OrganizationRoleEnum>[]
  defaultRole: OrganizationRoleEnum
  initUser?: User
  isFirstMegaUser?: boolean
}

export default function UserFormComponent({
  onSubmit,
  selectOptions,
  defaultRole,
  initUser = undefined,
  isFirstMegaUser,
}: Readonly<IProps>): React.JSX.Element {
  const { isDomainNameInvalid } = useContext(DomainNameValidatorContext)
  const [invitationForm, setInvitationForm] = useState<UserForm>(userFormFromUser(initUser))
  const [error, setError] = useState<any>({})
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const isCreation = useMemo(() => initUser === undefined, [initUser])
  const navigate = useNavigate()
  const { organization } = useContext(AdminOrganizationContext)

  function userFormFromUser(user: User | undefined): UserForm {
    if (!user) {
      return {
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        userOrganizationRole: defaultRole,
      }
    } else {
      return {
        firstName: user.givenName,
        lastName: user.familyName,
        phone: user.phone,
        email: user.email,
        userOrganizationRole: user.role,
      }
    }
  }

  function validate(): boolean {
    const newError: Record<string, any> = {}
    let isValid = true

    if (!invitationForm.firstName) {
      newError.firstName = "Le prénom doit être renseigné"
      isValid = false
    }

    if (!invitationForm.lastName) {
      newError.lastName = "Le nom doit être renseigné"
      isValid = false
    }

    if (!invitationForm.phone) {
      newError.phone = "Le numéro de téléphone doit être renseigné"
      isValid = false
    } else if (!isPhoneValid(invitationForm.phone)) {
      newError.phone =
        'Le numéro de téléphone doit être composé uniquement de chiffres et faire 10 caractères ou commencer par "+" et faire 12 caractères'
      isValid = false
    }

    if (!invitationForm.email) {
      newError.email = "L'email doit être renseigné"
      isValid = false
    } else if (!isEmailValid(invitationForm.email)) {
      newError.email = "L'adresse email ne respecte pas la forme classique : email@complement.fin"
      isValid = false
    } else if (isDomainNameInvalid(invitationForm.email)) {
      newError.email = "Time To Beem est un service B2B. Merci d'utiliser une adresse email professionnelle"
      isValid = false
    }

    setError(newError)
    return isValid
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault()

    if (!validate()) {
      return
    }

    invitationForm.email = invitationForm.email.toLowerCase()

    setIsSubmitting(true)
    onSubmit(invitationForm).finally(() => setIsSubmitting(false))
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const target = event.target

    setInvitationForm({
      ...invitationForm,
      [target.id]: target.value,
    })
  }

  function handleChangeRole(newRole: OrganizationRoleEnum): void {
    setInvitationForm({
      ...invitationForm,
      userOrganizationRole: newRole,
    })
  }

  return (
    <Container maxWidth="md">
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Box component="form" onSubmit={handleSubmit}>
          {!isFirstMegaUser && (
            <Typography variant="h6" sx={{ pt: "-200px" }}>
              {isCreation ? "Inscription" : "Modifier"}
            </Typography>
          )}
          {isFirstMegaUser && (
            <Typography variant="h6" sx={{ pt: "-200px" }}>
              Inscription du 1er méga user
            </Typography>
          )}
          <Paper variant="outlined" sx={{ my: { xs: 3, md: 3 }, p: { xs: 2, md: 3 } }}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="firstName"
                  variant="standard"
                  label="Prenom"
                  value={invitationForm.firstName}
                  fullWidth
                  onChange={handleChange}
                  error={!!error.firstName}
                  helperText={error.firstName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="lastName"
                  variant="standard"
                  label="Nom"
                  value={invitationForm.lastName}
                  fullWidth
                  onChange={handleChange}
                  error={!!error.lastName}
                  helperText={error.lastName}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <PhoneInput form={invitationForm} error={error} handleChange={handleChange} required />
              </Grid>
              <Grid item xs={12} sm={9} />
              <Grid item xs={12} sm={9}>
                <BaseSelectInput
                  id="role"
                  label="Rôle"
                  mode="direct"
                  handleChange={handleChangeRole}
                  options={selectOptions}
                  selectedOption={invitationForm.userOrganizationRole}
                />
              </Grid>
              <Grid item xs={12} sm={9}>
                <TextField
                  id="email"
                  variant="standard"
                  label="Email"
                  value={invitationForm.email}
                  fullWidth
                  onChange={handleChange}
                  error={!!error.email}
                  helperText={error.email}
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
              {isSubmitting ? (
                <CircularProgress />
              ) : (
                <>
                  {isFirstMegaUser && (
                    <Button
                      sx={{ mr: 2 }}
                      onClick={() => navigate(resolveUrl(adminPagesUrl.ADMIN_ORGANIZATION_PAGE, [organization?.id], {}))}>
                      Ignorer
                    </Button>
                  )}
                  <Button variant="contained" type="submit">
                    {isCreation ? "Inviter" : "Modifier"}
                  </Button>
                </>
              )}
            </Box>
          </Paper>
        </Box>
      </Box>
    </Container>
  )
}
