import { Checkbox } from "@mui/material"
import React, { ChangeEvent } from "react"

interface IProps {
  id: string
  checked: boolean

  handleChange(event: ChangeEvent<HTMLInputElement>): void
}

export function CheckboxInputNoLabel({ id, checked, handleChange }: IProps): React.JSX.Element {
  return <Checkbox id={id} checked={checked} onChange={handleChange} />
}
