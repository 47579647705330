import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, IconButton } from "@mui/material"
import React, { useContext } from "react"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import AddIcon from "@mui/icons-material/Add"
import { codeToKey } from "../../../../../../core/services/code-service"
import { CodeDisplayer } from "../../../../components/BSBimModelExtractedCode/CodeDisplayer"
import { BSMaterialResultListHeader } from "../BSMaterialList/BSMaterialResultListHeader"
import { BSMaterialResultList } from "../BSMaterialList/BSMaterialResultList"
import { BSItem } from "../../../../../../core/dto/beem-shot/BSItem/BSItem"
import { stringToNumber } from "../../../../../../core/services/helper-service"
import { useAccordion } from "../../../../BSCalculationPage/BSCalculationForm/components/AdvancedQuestionnaire/useAccordion"
import { BSItemContext } from "../../../../../../core/context/beem-shot/BSItems/BSItemContext"
import { BSItemSubCategory } from "../../../../../../core/dto/beem-shot/BSItem/BSItemSubCategory"
import { BSCustomMaterialDialogContext } from "../dialog/BSCustomMaterialDialog/BSCustomMaterialDialogContext"

function sortBSItem(a: BSItem, b: BSItem): number {
  if (a.type.label < b.type.label) {
    return -1
  } else if (a.type.label > b.type.label) {
    return 1
  } else {
    return stringToNumber(a.codeExtrait?.occurence) - stringToNumber(b.codeExtrait?.occurence)
  }
}

interface IProps {
  subCategory: BSItemSubCategory
}

export function BSItemList({ subCategory }: Readonly<IProps>): React.JSX.Element {
  const { bsItems } = useContext(BSItemContext)
  const { openCustomMaterialDialog } = useContext(BSCustomMaterialDialogContext)

  const { expanded: expandedBSItem, handleExpanded: handleBsExpanded } = useAccordion()

  return (
    <>
      {bsItems
        .filter((bsItem) => bsItem.subCategory.name === subCategory.name)
        .sort(sortBSItem)
        .map((bsItem) => (
          <Accordion
            id={bsItem.id}
            key={bsItem.id}
            expanded={expandedBSItem === bsItem.id}
            onChange={() => handleBsExpanded(bsItem.id)}
            elevation={1}
            square
            disableGutters
            TransitionProps={{ unmountOnExit: true }}
            sx={{
              m: 1,
              position: "inherit",
              borderRadius: 6,
              minWidth: "100%",
            }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box
                id={bsItem.codeExtrait ? codeToKey(bsItem.codeExtrait) : ""}
                sx={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                <CodeDisplayer
                  codeTitle={bsItem.type.label}
                  codeDescription={bsItem.codeExtrait?.descriptionFromBimModel}
                  codeExtrait={bsItem.codeExtrait}
                  codeColor={bsItem.codeExtrait?.color ?? "red"}
                  showBorder={false}
                />
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container rowGap={1}>
                <BSMaterialResultListHeader />
                <BSMaterialResultList bsItem={bsItem} />
                <Grid item xs={11} />
                <Grid item xs={1} display="flex" justifyContent="center">
                  <IconButton
                    onClick={() => openCustomMaterialDialog(bsItem, undefined)}
                    sx={{
                      bgcolor: "primary.main",
                      ":hover": { cursor: "pointer", backgroundColor: "primary.dark" },
                    }}>
                    <AddIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
    </>
  )
}
