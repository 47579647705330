import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Children } from "../../../../components/miscellianous/children"
import { BSMaterialResult } from "../../../dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { useBSMaterialResults } from "../../../hooks/beem-shot/useBSMaterialResults"
import { BSProjectContext } from "../BSProject/BSProjectContext"
import { BSVariantContext } from "../BSVariant/BSVariantContext"
import { SuccessContext } from "../../../../components/layout/success-snackbar"
import { BSCustomMaterialCreationDto } from "../../../dto/beem-shot/BSMaterialResult/BSCustomMaterialCreationDto"
import { BSUpdateMaterialResult } from "../../../dto/beem-shot/BSMaterialResult/BSUpdateMaterialResult"

export const BSMaterialResultContext = React.createContext<BsMaterialResultStore>({} as BsMaterialResultStore)

export function BSMaterialContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const {
    fetchBSMaterialResult,
    updateBSMaterialQuantities,
    resetBSMaterialQuantities,
    addBSOverriddenMaterial,
    putEnable,
    postCustomMaterial,
    deleteCustomMaterial,
  } = useBSMaterialResults()

  const openSuccessSnackbar: (message: string) => void = useContext(SuccessContext)
  const { bsProject } = useContext(BSProjectContext)
  const { selectedVariant } = useContext(BSVariantContext)

  const [bsMaterialResults, setBSMaterialResults] = useState<BSMaterialResult[]>([])
  const [materialUpdatingDisable, setMaterialUpdatingDisable] = useState<Record<string, boolean>>({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    if (selectedVariant?.id) {
      fetchBSMaterialResult(selectedVariant?.id).then((x) => setBSMaterialResults(x))
    }
  }, [fetchBSMaterialResult, selectedVariant?.id, bsProject])

  const getAllMaterialResult = useCallback(
    (variantId: string): Promise<void> =>
      fetchBSMaterialResult(variantId).then((newBsMaterialResultList) => setBSMaterialResults(newBsMaterialResultList)),
    [fetchBSMaterialResult]
  )

  const resetMaterial = useCallback(
    (variantId: string, materialResultId: string): Promise<void> =>
      resetBSMaterialQuantities(variantId, materialResultId).then(() => {
        if (selectedVariant?.id) {
          getAllMaterialResult(selectedVariant?.id)
        }
      }),
    [getAllMaterialResult, resetBSMaterialQuantities, selectedVariant?.id]
  )

  const deleteMaterial = useCallback(
    (bsMaterialResult: BSMaterialResult): Promise<void> => {
      if (bsMaterialResult.customMaterialId) {
        return deleteCustomMaterial(bsMaterialResult.customMaterialId).then(() => {
          if (selectedVariant?.id) {
            getAllMaterialResult(selectedVariant?.id)
          }
        })
      }
      return Promise.resolve()
    },
    [deleteCustomMaterial, getAllMaterialResult, selectedVariant?.id]
  )

  const updateMaterialQuantities = useCallback(
    (variantId: string, materialResultId: string, quantity: string): Promise<void> =>
      updateBSMaterialQuantities(variantId, materialResultId, quantity).then(() => {
        if (selectedVariant?.id) {
          fetchBSMaterialResult(selectedVariant?.id).then((newBsMaterialList) => setBSMaterialResults(newBsMaterialList))
        }
      }),
    [fetchBSMaterialResult, selectedVariant?.id, updateBSMaterialQuantities]
  )

  const updateIniesRecordMaterial = useCallback(
    (bsUpdateMaterialResult: BSUpdateMaterialResult): Promise<void> => {
      setIsSubmitting(true)
      if (selectedVariant?.id) {
        const variantId = selectedVariant.id
        return addBSOverriddenMaterial(variantId, bsUpdateMaterialResult)
          .then(() => {
            fetchBSMaterialResult(variantId).then((newBsMaterialResultList) => setBSMaterialResults(newBsMaterialResultList))
          })
          .then(() => {
            openSuccessSnackbar("Le matériau a bien été modifié !")
          })
          .finally(() => setIsSubmitting(false))
      } else {
        return Promise.resolve()
      }
    },
    [addBSOverriddenMaterial, fetchBSMaterialResult, openSuccessSnackbar, selectedVariant]
  )

  const setEnable = useCallback(
    (bsMaterialResultId: string, newValue: boolean): void => {
      setMaterialUpdatingDisable((prevState) => ({ ...prevState, [bsMaterialResultId]: true }))
      putEnable(bsMaterialResultId, newValue)
        .then((bsMaterialResult: BSMaterialResult) => {
          setBSMaterialResults((prevState: BSMaterialResult[]) =>
            prevState.map((result) => (result.id === bsMaterialResultId ? bsMaterialResult : result))
          )
        })
        .finally(() => {
          setMaterialUpdatingDisable((prevState) => ({ ...prevState, [bsMaterialResultId]: false }))
        })
    },
    [putEnable]
  )

  const addCustomMaterial = useCallback(
    (bsCustomMaterialCreation: BSCustomMaterialCreationDto): Promise<void> =>
      postCustomMaterial(bsCustomMaterialCreation).then((bsMaterialResult) => {
        setBSMaterialResults((prevState) => [...prevState, bsMaterialResult])
      }),
    [postCustomMaterial]
  )

  const bsMaterialResultStore = useMemo(
    () => ({
      isSubmitting,
      bsMaterialResults,
      setBSMaterialResults,
      updateMaterialQuantities,
      getAllMaterialResult,
      resetMaterial,
      setEnable,
      materialUpdatingDisable,
      updateIniesRecordMaterial,
      addCustomMaterial,
      deleteMaterial,
    }),
    [
      isSubmitting,
      bsMaterialResults,
      updateMaterialQuantities,
      getAllMaterialResult,
      resetMaterial,
      setEnable,
      materialUpdatingDisable,
      updateIniesRecordMaterial,
      addCustomMaterial,
      deleteMaterial,
    ]
  )
  return <BSMaterialResultContext.Provider value={bsMaterialResultStore}>{children}</BSMaterialResultContext.Provider>
}

export type BsMaterialResultStore = {
  bsMaterialResults: BSMaterialResult[]
  setBSMaterialResults: React.Dispatch<React.SetStateAction<BSMaterialResult[]>>
  materialUpdatingDisable: Record<string, boolean>
  isSubmitting: boolean
  updateMaterialQuantities(variantId: string, materialResultId: string, quantity: string): Promise<void>
  getAllMaterialResult(variantId: string): Promise<void>
  resetMaterial(variantId: string, materialResultId: string): Promise<void>
  updateIniesRecordMaterial(bsUpdateMaterialResult: BSUpdateMaterialResult): Promise<void>
  setEnable(bsMaterialResultId: string, newValue: boolean): void
  addCustomMaterial(bsMaterial: BSCustomMaterialCreationDto): Promise<void>
  deleteMaterial(bsMaterialResult: BSMaterialResult): Promise<void>
}
