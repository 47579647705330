import { Grid, Typography } from "@mui/material"
import React from "react"

export function BSMaterialResultListHeader(): React.JSX.Element {
  return (
    <Grid item container xs={12}>
      <Grid item xs={1} display="flex" justifyContent="center">
        <Typography variant="subtitle2" fontSize={12} sx={{ color: "#8F8F8F", textAlign: "center" }}>
          Activé
        </Typography>
      </Grid>
      <Grid item container xs={11}>
        <Grid item xs={2}>
          <Typography variant="subtitle2" fontSize={12} sx={{ color: "#8F8F8F", textAlign: "center" }}>
            Identifiant
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="subtitle2" fontSize={12} sx={{ color: "#8F8F8F" }}>
            Nom du produit
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="subtitle2" fontSize={12} sx={{ color: "#8F8F8F", textAlign: "center" }}>
            Quantité
          </Typography>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </Grid>
  )
}
