import React, { useCallback, useContext, useMemo, useState } from "react"
import { BSMaterialResult } from "../../../../../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { Children } from "../../../../../../../components/miscellianous/children"
import { BSDeleteCustomDialog } from "./BSDeleteCustomDialog"
import { SelectionContext } from "../../context/SelectionContext"

export const BSDeleteCustomDialogContext = React.createContext<BSDeleteCustomDialogStore>({} as BSDeleteCustomDialogStore)

export function BSDeleteCustomDialogContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const [isOpen, setIsOpen] = useState(false)
  const { setSelectedBSMaterialResult, selectedBSMaterialResult } = useContext(SelectionContext)

  const onClose = useCallback((): void => {
    setIsOpen(false)
  }, [])

  const openDialog = useCallback(
    (bsMaterialResult: BSMaterialResult | undefined): void => {
      setSelectedBSMaterialResult(bsMaterialResult)
      setIsOpen(true)
    },
    [setSelectedBSMaterialResult]
  )

  const bsDeleteCustomDialogStore: BSDeleteCustomDialogStore = useMemo(
    () => ({ openDeleteCustomDialog: openDialog }),
    [openDialog]
  )

  return (
    <BSDeleteCustomDialogContext.Provider value={bsDeleteCustomDialogStore}>
      {selectedBSMaterialResult && <BSDeleteCustomDialog isOpen={isOpen} handleClose={onClose} />}
      {children}
    </BSDeleteCustomDialogContext.Provider>
  )
}

export type BSDeleteCustomDialogStore = {
  openDeleteCustomDialog(bsMaterialResult: BSMaterialResult | undefined): void
}
