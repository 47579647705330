import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder"
import ForumIcon from "@mui/icons-material/Forum"
import WorkOutlineIcon from "@mui/icons-material/WorkOutline"
import { Autocomplete, Box, Button, CircularProgress, Grid, TextField, Typography } from "@mui/material"
import React, { ChangeEvent, FormEvent, useContext, useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { CheckboxInput } from "../../../components/inputs/checkbox-input/CheckboxInput"
import { DateInput } from "../../../components/inputs/date-input/date-input"
import ListInput from "../../../components/inputs/list-input/list-input"
import NumberLicenseInput from "../../../components/inputs/number-license-input/number-license-input"
import PhoneInput from "../../../components/inputs/phone-input/phone-input"
import { ErrorContext } from "../../../components/layout/error-snackbar"
import { SuccessContext } from "../../../components/layout/success-snackbar"
import { adminPagesUrl } from "../../../core/appConstants"
import { AdminOrganizationContext } from "../../../core/context/organization/admin-organization-context"
import { OrganizationsListContext } from "../../../core/context/organization/organization-list-context"
import { Address } from "../../../core/dto/address"
import { Organization } from "../../../core/dto/organization/organization"
import { OrganizationAdminCreateOrUpdate } from "../../../core/dto/organization/organization-admin-create-or-update"
import { RoleEnum } from "../../../core/enum/roleEnum"
import { useOrganization } from "../../../core/hooks/use-organization"
import { compareIgnoreCase, licenseNumberToString, licenseStringValueToNumber } from "../../../core/services/helper-service"
import { resolveUrl } from "../../../core/services/http-service"
import { roleList, roleOnProjectToLabel } from "../../../core/services/role-service"
import { isPhoneValid } from "../../../core/services/user-service"
import { NumberInput } from "../../../components/inputs/number-input/NumberInput"

const addressKeys: string[] = Object.keys(new Address())

type OrganizationForm = {
  businessName: string
  address: Address
  email: string
  contactName: string
  phone: string
  siret: string
  vatNumber: string
  roles: RoleEnum[]
  domainNames: string[]

  beemMasterOption: boolean
  licenceCounter: number
  maxLicenceCount: string
  projectCounter: number
  maxProjectCount: string
  invitedCounter: number
  maxInvitedCount: string
  bmEndingDate: Date | undefined

  beemPilotOption: boolean
  rseeDocumentCounter: number
  maxRseeDocumentCount: string
  globalDashboardOption: boolean
  bpEndingDate: Date | undefined

  beemShotOption: boolean
  bsProjectCounter: number
  bsMaxProjectCount: string
  bsInvitedCounter: number
  bsMaxInvitedCount: string
  bsEndingDate: Date | undefined

  plugInRevitOption: boolean
  plugInRevitEndingDate: Date | undefined
}

function organizationFormFromOrganization(organization: Organization | undefined): OrganizationForm {
  if (organization) {
    return {
      businessName: organization.businessName,
      address: organization.address,
      email: organization.email,
      contactName: organization.contactName,
      phone: organization.phone,
      siret: organization.siret,
      vatNumber: organization.vatNumber,
      roles: organization.roles,
      domainNames: organization.domainNames,

      beemMasterOption: organization.beemMasterOption,
      licenceCounter: organization.licenceCounter,
      maxLicenceCount: licenseNumberToString(organization.maxLicenceCount),
      projectCounter: organization.projectCounter,
      maxProjectCount: licenseNumberToString(organization.maxProjectCount),
      invitedCounter: organization.invitedCounter,
      maxInvitedCount: licenseNumberToString(organization.maxInvitedCount),
      bmEndingDate: organization.bmEndingDate,

      beemPilotOption: organization.beemPilotOption,
      rseeDocumentCounter: organization.rseeDocumentCounter,
      maxRseeDocumentCount: licenseNumberToString(organization.maxRseeDocumentCount),
      globalDashboardOption: organization.globalDashboardOption,
      bpEndingDate: organization.bpEndingDate,

      beemShotOption: organization.beemShotOption,
      bsProjectCounter: organization.bsProjectCounter,
      bsMaxProjectCount: licenseNumberToString(organization.bsMaxProjectCount),
      bsInvitedCounter: organization.bsInvitedCounter,
      bsMaxInvitedCount: licenseNumberToString(organization.bsMaxInvitedCount),
      bsEndingDate: organization.bsEndingDate,

      plugInRevitOption: organization.plugInRevitOption,
      plugInRevitEndingDate: organization.plugInRevitEndingDate,
    }
  } else {
    return {
      businessName: "",
      address: new Address(),
      email: "",
      contactName: "",
      phone: "",
      siret: "",
      vatNumber: "",
      roles: [],
      domainNames: [],

      beemMasterOption: false,
      licenceCounter: 0,
      maxLicenceCount: "0",
      projectCounter: 0,
      maxProjectCount: "0",
      invitedCounter: 0,
      maxInvitedCount: "0",
      bmEndingDate: new Date(),

      beemPilotOption: false,
      rseeDocumentCounter: 0,
      maxRseeDocumentCount: "-1",
      globalDashboardOption: false,
      bpEndingDate: new Date(),

      beemShotOption: false,
      bsProjectCounter: 0,
      bsMaxProjectCount: "0",
      bsInvitedCounter: 0,
      bsMaxInvitedCount: "0",
      bsEndingDate: new Date(),

      plugInRevitOption: false,
      plugInRevitEndingDate: new Date(),
    }
  }
}

function formToDto(organizationForm: OrganizationForm, organizationId: string | undefined): OrganizationAdminCreateOrUpdate {
  return {
    id: organizationId,
    businessName: organizationForm.businessName,
    address: organizationForm.address,
    email: organizationForm.email.toLocaleLowerCase(),
    contactName: organizationForm.contactName,
    phone: organizationForm.phone,
    siret: organizationForm.siret,
    vatNumber: organizationForm.vatNumber,
    roles: organizationForm.roles,
    domainNames: organizationForm.domainNames,

    beemMasterOption: organizationForm.beemMasterOption,
    licenceCounter: organizationForm.licenceCounter,
    maxLicenceCount: licenseStringValueToNumber(organizationForm.maxLicenceCount),
    projectCounter: organizationForm.projectCounter,
    maxProjectCount: licenseStringValueToNumber(organizationForm.maxProjectCount),
    invitedCounter: organizationForm.invitedCounter,
    maxInvitedCount: licenseStringValueToNumber(organizationForm.maxInvitedCount),
    bmEndingDate: organizationForm.bmEndingDate,

    beemPilotOption: organizationForm.beemPilotOption,
    rseeDocumentCounter: organizationForm.rseeDocumentCounter,
    maxRseeDocumentCount: licenseStringValueToNumber(organizationForm.maxRseeDocumentCount),
    globalDashboardOption: organizationForm.globalDashboardOption,
    bpEndingDate: organizationForm.bpEndingDate,

    beemShotOption: organizationForm.beemShotOption,
    bsProjectCounter: organizationForm.bsProjectCounter,
    bsMaxProjectCount: licenseStringValueToNumber(organizationForm.bsMaxProjectCount),
    bsInvitedCounter: organizationForm.bsInvitedCounter,
    bsMaxInvitedCount: licenseStringValueToNumber(organizationForm.bsMaxInvitedCount),
    bsEndingDate: organizationForm.bsEndingDate,

    plugInRevitOption: organizationForm.plugInRevitOption,
    plugInRevitEndingDate: organizationForm.plugInRevitEndingDate,
  }
}

export function AdminOrganizationForm(): React.JSX.Element {
  const navigate = useNavigate()
  const { organization, setOrganization, isOrganizationLoading } = useContext(AdminOrganizationContext)
  const { organizationsList, setOrganizationsList } = useContext(OrganizationsListContext)
  const openErrorSnackBar = useContext(ErrorContext)
  const { createOrUpdateOrganizationAsUltimate } = useOrganization()
  const openSuccessSnackbar = useContext(SuccessContext)

  const [errors, setErrors] = useState<Record<string, string>>({})
  const [organizationForm, setOrganizationForm] = useState<OrganizationForm>(organizationFormFromOrganization(organization))

  const isCreation: boolean = useMemo(() => organization === undefined, [organization])

  useEffect(() => {
    const newForm = organizationFormFromOrganization(organization)
    setOrganizationForm(newForm)
  }, [organization])

  function handleChange(event: ChangeEvent<HTMLInputElement>): void {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.value

    setErrors({ ...errors, [target.id]: "" })

    if (addressKeys.includes(target.id)) {
      const address: Address = { ...organizationForm.address, [target.id]: value }
      setOrganizationForm({ ...organizationForm, address })
    } else {
      setOrganizationForm({
        ...organizationForm,
        [target.id]: value,
      })
    }
  }

  function handleChangeLicense(fieldId: string, newValue: string): void {
    setErrors({ ...errors, [fieldId]: "" })
    setOrganizationForm({
      ...organizationForm,
      [fieldId]: newValue,
    })
  }

  function handleChangeDate(fieldId: string): (date: Date | undefined) => void {
    return (date: Date | undefined) => {
      setErrors({ ...errors, [fieldId]: "" })
      setOrganizationForm({
        ...organizationForm,
        [fieldId]: date,
      })
    }
  }

  function onRoleChange(event: any, newValue: RoleEnum[]): void {
    setOrganizationForm({
      ...organizationForm,
      roles: newValue,
    })
  }

  function testIfEmpty(
    newError: Record<string, string>,
    isValid: { value: boolean },
    form: OrganizationAdminCreateOrUpdate,
    fieldName: keyof OrganizationAdminCreateOrUpdate
  ): void {
    if (form[fieldName] === undefined || form[fieldName] === "") {
      newError[fieldName] = "Ce champ est est requis"
      isValid.value = false
    }
  }

  function testIfAddressEmpty(
    newError: Record<string, string>,
    isValid: { value: boolean },
    form: Address,
    fieldName: keyof Address
  ): void {
    if (!form[fieldName] || form[fieldName] === "") {
      newError[fieldName] = "Ce champ est est requis"
      isValid.value = false
    }
  }

  function validate(form: OrganizationAdminCreateOrUpdate): boolean {
    const newError: Record<string, string> = {}
    const isValid = { value: true }

    testIfEmpty(newError, isValid, form, "businessName")
    testIfEmpty(newError, isValid, form, "email")
    testIfEmpty(newError, isValid, form, "phone")
    testIfEmpty(newError, isValid, form, "siret")
    testIfEmpty(newError, isValid, form, "vatNumber")
    testIfEmpty(newError, isValid, form, "licenceCounter")
    testIfEmpty(newError, isValid, form, "maxLicenceCount")
    testIfEmpty(newError, isValid, form, "projectCounter")
    testIfEmpty(newError, isValid, form, "maxProjectCount")
    testIfEmpty(newError, isValid, form, "invitedCounter")
    testIfEmpty(newError, isValid, form, "maxInvitedCount")
    testIfEmpty(newError, isValid, form, "rseeDocumentCounter")
    testIfEmpty(newError, isValid, form, "maxRseeDocumentCount")

    if (!form.address) {
      form.address = new Address()
    }
    testIfAddressEmpty(newError, isValid, form.address, "street")
    testIfAddressEmpty(newError, isValid, form.address, "city")
    testIfAddressEmpty(newError, isValid, form.address, "zipCode")

    if (Number.isNaN(form.maxLicenceCount)) {
      newError.maxLicenceCount = "La valeur doit être soit un entier positif, soit -1, soit Illimité"
      isValid.value = false
    }
    if (Number.isNaN(form.maxRseeDocumentCount)) {
      newError.maxRseeProjectCount = "La valeur doit être soit un entier positif, soit -1, soit Illimité"
      isValid.value = false
    }
    if (Number.isNaN(form.maxInvitedCount)) {
      newError.maxInvitedCount = "La valeur doit être soit un entier positif, soit -1, soit Illimité"
      isValid.value = false
    }
    if (Number.isNaN(form.maxProjectCount)) {
      newError.maxProjectCount = "La valeur doit être soit un entier positif, soit -1, soit Illimité"
      isValid.value = false
    }

    if (!isPhoneValid(form.phone)) {
      newError.phone =
        'Le numéro de téléphone doit être composé uniquement de chiffres et faire 10 caractères ou commencer par "+" et faire 12 caractères'
      isValid.value = false
    }

    setErrors(newError)
    return isValid.value
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault()

    const adminCreatOrUpdateDto = formToDto(organizationForm, organization?.id)

    let successMessage = ""
    if (validate(adminCreatOrUpdateDto)) {
      createOrUpdateOrganizationAsUltimate(adminCreatOrUpdateDto)
        .then((createdOrganization) => {
          if (organization) {
            // Update
            const newOrganizationsList = [...organizationsList].filter((orga) => orga.id !== createdOrganization.id)
            newOrganizationsList.push(createdOrganization)
            newOrganizationsList.sort((a, b) => compareIgnoreCase(a.businessName, b.businessName))
            setOrganizationsList(newOrganizationsList)
            setOrganization(createdOrganization)
            navigate(resolveUrl(adminPagesUrl.ADMIN_ORGANIZATION_PAGE, [organization?.id]))
            successMessage = "L'organisation a été correctement mis à jour"
          } else {
            // Created
            const newOrganizationsList = [...organizationsList, createdOrganization]
            newOrganizationsList.sort((a, b) => compareIgnoreCase(a.businessName, b.businessName))
            setOrganizationsList(newOrganizationsList)
            setOrganization(createdOrganization)
            navigate(resolveUrl(adminPagesUrl.ADMIN_ORGANIZATION_INVITE_FIRST_MEGA_USER, ["new"]))
            successMessage = "L'organisation a été correctement créé"
          }
        })
        .then(() => {
          openSuccessSnackbar(successMessage)
        })
        .catch((err) => openErrorSnackBar(err))
    }
  }

  function addDomainName(domainName: string): void {
    setOrganizationForm({ ...organizationForm, domainNames: [...organizationForm.domainNames, domainName] })
  }

  function removeDomainName(domainName: string): void {
    const updatedDomainNames = organizationForm.domainNames.filter((name) => name !== domainName)

    setOrganizationForm({ ...organizationForm, domainNames: updatedDomainNames })
  }

  if (isOrganizationLoading) {
    return <CircularProgress />
  } else {
    return (
      <Box component="form" onSubmit={handleSubmit} sx={{ minWidth: "10vw" }}>
        <Grid container>
          <Grid
            container
            item
            sm={6}
            columnGap={3}
            rowGap={3}
            display="flex"
            justifyContent="center"
            sx={{ borderRight: 1 }}
            height="100%">
            <Grid item xs={12} sm={11}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <WorkOutlineIcon sx={{ fontSize: "30px", marginRight: "16px" }} />
                <Box>
                  <Typography variant="h1" sx={{ fontSize: 20, fontWeight: "bold" }}>
                    Informations organisations
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={10}>
              <TextField
                id="businessName"
                value={organizationForm?.businessName}
                label="Nom de l'organisation *"
                onChange={handleChange}
                variant="standard"
                fullWidth
                error={!!errors.businessName}
                helperText={errors.businessName}
              />
            </Grid>
            <Grid item xs={12} sm={10}>
              <TextField
                id="vatNumber"
                value={organizationForm.vatNumber}
                label="Numéros de TVA intracommunautaire *"
                onChange={handleChange}
                type="string"
                variant="standard"
                fullWidth
                error={!!errors.vatNumber}
                helperText={errors.vatNumber}
              />
            </Grid>
            <Grid item xs={12} sm={10}>
              <TextField
                id="siret"
                value={organizationForm.siret}
                label="Siret *"
                onChange={handleChange}
                type="string"
                variant="standard"
                fullWidth
                error={!!errors.siret}
                helperText={errors.siret}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                id="street"
                value={organizationForm.address?.street}
                label="Adresse"
                onChange={handleChange}
                type="string"
                variant="standard"
                fullWidth
                error={!!errors.street}
                helperText={errors.street}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                id="additional"
                value={organizationForm.address?.additional}
                label="Complément d'adresse"
                onChange={handleChange}
                type="string"
                variant="standard"
                fullWidth
                error={!!errors.additional}
                helperText={errors.additional}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                id="city"
                value={organizationForm.address?.city}
                label="Ville *"
                onChange={handleChange}
                type="string"
                variant="standard"
                fullWidth
                error={!!errors.city}
                helperText={errors.city}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                id="zipCode"
                value={organizationForm.address?.zipCode}
                label="Code postal *"
                onChange={handleChange}
                type="string"
                variant="standard"
                fullWidth
                error={!!errors.zipCode}
                helperText={errors.zipCode}
              />
            </Grid>
            <Grid item xs={12} sm={10}>
              <Autocomplete
                multiple
                options={roleList}
                value={organizationForm.roles}
                onChange={onRoleChange}
                getOptionLabel={(value: RoleEnum) => roleOnProjectToLabel(value)}
                renderInput={(params) => <TextField {...params} label="Rôles" />}
              />
            </Grid>
            <Grid item xs={12} sm={10}>
              {!isCreation && (
                <ListInput
                  values={organizationForm?.domainNames}
                  label={organizationForm?.domainNames.length > 1 ? "Noms de domaine" : "Nom de domaine"}
                  addValue={addDomainName}
                  removeValue={removeDomainName}
                />
              )}
            </Grid>

            <Grid item xs={12} sm={11}>
              <Box sx={{ display: "flex", flexDirection: "row", mt: 5 }}>
                <ForumIcon sx={{ fontSize: "30px", marginRight: "16px" }} />
                <Box>
                  <Typography variant="h1" sx={{ fontSize: 20, fontWeight: "bold" }}>
                    Contact
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={10}>
              <TextField
                id="email"
                value={organizationForm?.email}
                label="Email de contact *"
                onChange={handleChange}
                type="string"
                variant="standard"
                fullWidth
                error={!!errors.email}
                helperText={errors.email}
              />
            </Grid>
            <Grid item xs={12} sm={10}>
              <TextField
                id="contactName"
                value={organizationForm?.contactName}
                label="Nom et Prénom"
                onChange={handleChange}
                type="string"
                variant="standard"
                fullWidth
                error={!!errors.contactName}
                helperText={errors.contactName}
              />
            </Grid>
            <Grid item xs={12} sm={10}>
              <PhoneInput form={organizationForm} error={errors} handleChange={handleChange} required />
            </Grid>
          </Grid>

          <Grid
            container
            item
            sm={6}
            display="flex"
            justifyContent="center"
            columnGap={5}
            rowGap={3}
            height="100%"
            sx={{ pl: 2 }}>
            <Grid item xs={12} sm={12} sx={{ ml: 5 }}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <BookmarkBorderIcon sx={{ fontSize: "30px", marginRight: "16px" }} />
                <Box>
                  <Typography variant="h1" sx={{ fontSize: 20, fontWeight: "bold" }}>
                    Offre commerciale
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sx={{ display: "flex", flexDirection: "column" }}>
              {/*Plug in revit*/}
              <CheckboxInput
                id="plugInRevitOption"
                label="Plug-in REVIT"
                checked={organizationForm.plugInRevitOption}
                handleChange={handleChange}
              />
              <DateInput
                label="Date de fin"
                value={organizationForm.plugInRevitEndingDate}
                handleChange={handleChangeDate("plugInRevitEndingDate")}
              />
            </Grid>
            <Grid item container columnGap={2} xs={12}>
              {/*Beem Shot*/}
              <Grid item xs={12}>
                <CheckboxInput
                  id="beemShotOption"
                  label="Beem Shot"
                  checked={organizationForm.beemShotOption}
                  handleChange={handleChange}
                />
                <DateInput
                  label="Date de fin"
                  value={organizationForm.bsEndingDate}
                  handleChange={handleChangeDate("bsEndingDate")}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <NumberLicenseInput
                  id="bsMaxProjectCount"
                  label="Nombre de projets Beem Shot maximum"
                  value={organizationForm.bsMaxProjectCount}
                  error={errors}
                  onChange={handleChangeLicense}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <NumberInput
                  id="bsProjectCounter"
                  value={organizationForm.bsProjectCounter}
                  label="Nombre de projets Beem Shot"
                  backgroundColor="#F5F5F5"
                  handleEventChange={handleChange}
                  errors={errors}
                  mode="event"
                />
              </Grid>
            </Grid>

            <Grid item container xs={12} columnGap={2}>
              {/*Beem Pilot*/}
              <Grid item xs={12}>
                <CheckboxInput
                  id="beemPilotOption"
                  label="Beem Pilot"
                  checked={organizationForm.beemPilotOption}
                  handleChange={handleChange}
                />
                <DateInput
                  label="Date de fin"
                  value={organizationForm.bpEndingDate}
                  handleChange={handleChangeDate("bpEndingDate")}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <NumberLicenseInput
                  id="maxRseeDocumentCount"
                  label="Nombre de documents RSEE maximum"
                  value={organizationForm.maxRseeDocumentCount}
                  error={errors}
                  onChange={handleChangeLicense}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <NumberInput
                  id="rseeDocumentCounter"
                  value={organizationForm.rseeDocumentCounter}
                  label="Nombre de documents RSEE utilisés *"
                  backgroundColor="#F5F5F5"
                  handleEventChange={handleChange}
                  errors={errors}
                  mode="event"
                />
              </Grid>
              <Grid item xs={12} sm={11}>
                <CheckboxInput
                  id="globalDashboardOption"
                  label="Tableau de bord général"
                  checked={organizationForm.globalDashboardOption}
                  handleChange={handleChange}
                />
              </Grid>
            </Grid>

            <Grid item container xs={12} columnGap={2}>
              {/*Beem Master*/}
              <Grid item xs={12}>
                <CheckboxInput
                  id="beemMasterOption"
                  label="Beem Master"
                  checked={organizationForm.beemMasterOption}
                  handleChange={handleChange}
                />
                <DateInput
                  label="Date de fin"
                  value={organizationForm.bmEndingDate}
                  handleChange={handleChangeDate("bmEndingDate")}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <NumberLicenseInput
                  id="maxLicenceCount"
                  label="Nombre de collaborateurs maximum"
                  value={organizationForm.maxLicenceCount}
                  error={errors}
                  onChange={handleChangeLicense}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <NumberInput
                  id="licenceCounter"
                  value={organizationForm?.licenceCounter}
                  label="Nombre de collaborateurs utilisés *"
                  backgroundColor="#F5F5F5"
                  handleEventChange={handleChange}
                  errors={errors}
                  mode="event"
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <NumberLicenseInput
                  id="maxProjectCount"
                  label="Nombre de projets BIM maximum"
                  value={organizationForm.maxProjectCount}
                  error={errors}
                  onChange={handleChangeLicense}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <NumberInput
                  id="projectCounter"
                  value={organizationForm.projectCounter}
                  label="Nombre de projets BIM utilisés *"
                  backgroundColor="#F5F5F5"
                  handleEventChange={handleChange}
                  errors={errors}
                  mode="event"
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <NumberLicenseInput
                  id="maxInvitedCount"
                  label={"Nombre d'invités maximum"}
                  value={organizationForm.maxInvitedCount}
                  error={errors}
                  onChange={handleChangeLicense}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <NumberInput
                  id="invitedCounter"
                  value={organizationForm.invitedCounter}
                  label="Nombre d'invités utilisés *"
                  backgroundColor="#F5F5F5"
                  handleEventChange={handleChange}
                  errors={errors}
                  mode="event"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container justifyContent="flex-end" sx={{ mb: 2 }}>
          <Grid item xs={1}>
            <Button type="submit" variant="contained" sx={{ mt: 3, ml: 1, mr: 2 }}>
              {organization ? "Mettre à jour" : "Suivant"}
            </Button>
          </Grid>
        </Grid>
      </Box>
    )
  }
}
