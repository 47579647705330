import { BottomNavigation, Paper } from "@mui/material"
import React, { useCallback, useContext, useEffect, useRef, useState } from "react"
import { ValidateButton } from "../../components/buttons/ValidateButton/ValidateButton"
import { useKairnial } from "./useKairnial"
import { SuccessContext } from "../../components/layout/success-snackbar"
import { TableauJob } from "../../core/dto/beem-shot/TableauJob/TableauJob"
import { TableauJobStatusEnum } from "../../core/dto/beem-shot/TableauJob/TableauJobStatusEnum"

function isJobOver(tableauJob: TableauJob): boolean {
  return tableauJob.status !== TableauJobStatusEnum.IN_PROGRESS
}

type IProps = {
  secureToken: string
  clientId: string
}

export function KairnialRefreshNavigation({ secureToken, clientId }: Readonly<IProps>): React.JSX.Element {
  const { refreshData, checkTableauJob } = useKairnial()
  const openSuccessSnackbar = useContext(SuccessContext)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const timerRef = useRef<NodeJS.Timeout | null>(null)

  const cancelTimer = useCallback((): void => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
      timerRef.current = null
    }
  }, [])

  const watchForJobEnd = useCallback(
    (tableauJob: TableauJob): void => {
      if (timerRef.current) {
        cancelTimer()
      }
      timerRef.current = setTimeout(() => {
        checkTableauJob(clientId, secureToken, tableauJob.id).then((freshTableauJob: TableauJob) => {
          if (isJobOver(freshTableauJob)) {
            setIsSubmitting(false)
            openSuccessSnackbar("Le rafraîchissement des données est terminé")
          }
        })
      }, 5000)
    },
    [cancelTimer, checkTableauJob, clientId, openSuccessSnackbar, secureToken]
  )

  const clearOnDestroy = useCallback(() => {
    if (timerRef.current) {
      cancelTimer()
    }
  }, [cancelTimer])

  useEffect(() => clearOnDestroy, [clearOnDestroy])

  const handleRefresh = useCallback((): void => {
    openSuccessSnackbar(
      "Le rafraîchissement des données est en cours. Les données devraient être à jour dans une dizaine de minutes."
    )
    setIsSubmitting(true)
    refreshData(secureToken, clientId).then((tableauJob) => {
      openSuccessSnackbar(`jobId${tableauJob}`)
      watchForJobEnd(tableauJob)
    })
  }, [clientId, openSuccessSnackbar, refreshData, secureToken, watchForJobEnd])

  return (
    <Paper>
      <BottomNavigation
        sx={{
          position: "fixed",
          bottom: 20,
          right: 20,
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          alignContent: "center",
        }}>
        <ValidateButton onClick={handleRefresh} label="Rafraîchir" isSubmitting={isSubmitting} />
      </BottomNavigation>
    </Paper>
  )
}
