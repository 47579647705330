import React, { useContext } from "react"
import { BSMaterialResult } from "../../../../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { codeToKey } from "../../../../../../core/services/code-service"
import { BSItem } from "../../../../../../core/dto/beem-shot/BSItem/BSItem"
import { BSMaterialResultContext } from "../../../../../../core/context/beem-shot/BSMaterialResult/BSMaterialResultContext"
import { BSMaterialResultRow } from "./BSMaterialResultRow"

interface IProps {
  bsItem: BSItem
}

export function BSMaterialResultList({ bsItem }: Readonly<IProps>): React.JSX.Element {
  const { bsMaterialResults } = useContext(BSMaterialResultContext)

  return (
    <>
      {bsMaterialResults
        .filter((bsMaterialResult: BSMaterialResult) => bsMaterialResult.itemType === bsItem.type.name)
        .filter((bsMaterialResult: BSMaterialResult) => {
          if (bsItem.codeExtrait) {
            return bsMaterialResult.codeOccurrence === codeToKey(bsItem.codeExtrait)
          } else {
            return bsMaterialResult.codeOccurrence === undefined
          }
        })
        .map((bsMaterialResult: BSMaterialResult) => (
          <BSMaterialResultRow key={bsMaterialResult.id} bsItem={bsItem} bsMaterialResult={bsMaterialResult} />
        ))}
    </>
  )
}
